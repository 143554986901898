import React from 'react';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import axios from '../../../utils/axiosInstance';
import endpoints from '../../../utils/apiEndpoints';
import {
  dispatchSuccessMessage,
  dispatchErrorMessage,
  dispatchWarningMessage,
  generateHttpErrorMessage,
  logErrorToConsole,
} from '../../../utils/helpers';
import * as types from '../actionTypes';
import { createAction, createActionType, REQUEST, SUCCESS, FAILURE } from '../index';
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';
import { getResourceById, RESOURCE_TYPES } from '../../../utils/resourceTypes';

export const resourceTypesActions = {
  request: () => createAction(types.RESOURCE_TYPES[REQUEST]),
  success: data => createAction(types.RESOURCE_TYPES[SUCCESS], { data }),
  failure: error => createAction(types.RESOURCE_TYPES[FAILURE], { error }),
  selected: selected => createAction(types.SELECT_RESOURCE_TYPE, { selected }),
  selectedSecondary: selected => createAction(types.SELECT_SECONDARY_RESOURCE_TYPE, { selected }),
  groupsRequest: CREATE => createAction(types[`${createActionType(CREATE)}GROUPS_RESOURCES`][REQUEST], { CREATE }),
  groupsSuccess: (data, CREATE) => createAction(types[`${createActionType(CREATE)}GROUPS_RESOURCES`][SUCCESS], { data, CREATE }),
  groupsFailure: (error, CREATE) => createAction(types[`${createActionType(CREATE)}GROUPS_RESOURCES`][FAILURE], { error, CREATE }),
  parentRequest: CREATE => createAction(types[`${createActionType(CREATE)}PARENT_RESOURCES`][REQUEST], { CREATE }),
  parentSuccess: (data, CREATE) => createAction(types[`${createActionType(CREATE)}PARENT_RESOURCES`][SUCCESS], { data, CREATE }),
  parentFailure: (error, CREATE) => createAction(types[`${createActionType(CREATE)}PARENT_RESOURCES`][FAILURE], { error, CREATE }),
  filter: filter => createAction(types.RESOURCE_TYPES_FILTER, { filter }),
  createSelected: selected => createAction(types.CREATE_SELECT_RESOURCE_TYPE, { selected }),
  createSelectedSecondary: selected => createAction(
    types.CREATE_SELECT_SECONDARY_RESOURCE_TYPE, { selected },
  ),
  createSelectedGroup: selected => createAction(
    types.CREATE_SELECT_GROUP_RESOURCE_TYPE, { selected },
  ),
  referenceRequest: () => createAction(types.EDIT_REFERENCE[REQUEST]),
  referenceSuccess: data => createAction(types.EDIT_REFERENCE[SUCCESS], { data }),
  referenceFailure: error => createAction(types.EDIT_REFERENCE[FAILURE], { error }),
  editReferenceSuccess: data => createAction(types.EDIT_REFERENCE_UPDATE[SUCCESS], { data }),
};

export function getResourceTypesAction() {
  return async (dispatch) => {
    try {
      dispatch(resourceTypesActions.request());
      const response = await axios.get(endpoints.resourceTypes());
      const { warnings } = response.data.meta;

      if (!_.isEmpty(warnings)) {
        dispatchWarningMessage(
          dispatch,
          <FormattedMessage
            id="actions.resource-types.warnings"
          />,
          0,
        );
      }

      dispatch(resourceTypesActions.success(response.data));

      return Promise.resolve(response.data);
    } catch (error) {
      logErrorToConsole(error);
      const { custom } = error;
      let intlKey;

      if (custom) {
        ({ intlKey } = error);
      } else {
        const errorMessage = generateHttpErrorMessage(error);
        ({ intlKey } = errorMessage);
      }

      dispatch(resourceTypesActions.failure(intlKey));

      return Promise.reject(error);
    }
  };
}

export function getSelectedParentResourceAction(resource, options = {}) {
  return async (dispatch) => {
    const CREATE = _.get(options, 'CREATE', '');
    const parentResourceType = _.get(resource, 'attributes.parentResourceType');

    try {
      const endpoint = endpoints.endpointForResource(parentResourceType);

      dispatch(resourceTypesActions.parentRequest(CREATE));
      const response = await axios.get(endpoint);
      dispatch(resourceTypesActions.parentSuccess(response.data, CREATE));
      return Promise.resolve(response.data);
    } catch (error) {
      logErrorToConsole(error);
      const { custom } = error;
      let intlKey;

      if (custom) {
        ({ intlKey } = error);
      } else {
        const errorMessage = generateHttpErrorMessage(error);
        ({ intlKey } = errorMessage);
      }

      dispatch(resourceTypesActions.parentFailure(intlKey, CREATE));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            parentResourceType,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function getSelectedGroupsResourceAction(resource, options = {}) {
  return async (dispatch) => {
    const CREATE = _.get(options, 'CREATE', '');
    const resourceEndpoint = _.get(resource, 'attributes.resourceEndpoint');
    try {
      const endpoint = endpoints.endpointForResource(resourceEndpoint, { creationGroups: true });
      dispatch(resourceTypesActions.groupsRequest(CREATE));

      const response = await axios.get(endpoint);

      dispatch(resourceTypesActions.groupsSuccess(response.data, CREATE));
      return Promise.resolve(response);
    } catch (error) {
      logErrorToConsole(error);
      const { custom } = error;
      let intlKey;

      if (custom) {
        ({ intlKey } = error);
      } else {
        const errorMessage = generateHttpErrorMessage(error);
        ({ intlKey } = errorMessage);
      }

      dispatch(resourceTypesActions.groupsFailure(intlKey, CREATE));

      return Promise.reject(error);
    }
  };
}

export function setSelectedResourceTypeAction(resource, getParent, getGroups) {
  return async (dispatch) => {
    try {
      const hasParent = _.get(resource, 'attributes.parentResourceType');
      dispatch(resourceTypesActions.selected(resource));
      try {
        if (getGroups) await dispatch(getSelectedGroupsResourceAction(resource));
        if (getParent && hasParent) await dispatch(getSelectedParentResourceAction(resource));
      } catch (error) {
        // Need to catch here, but error is handled below
      }
      return Promise.resolve(resource);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

export function setSecondarySelectedResourceTypeAction(resource) {
  return async (dispatch) => {
    try {
      dispatch(resourceTypesActions.selectedSecondary(resource));
      return Promise.resolve(resource);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

function clearSelectedParentResourceAction(CREATE) {
  return async (dispatch) => {
    dispatch({
      type: types[`CLEAR_${createActionType(CREATE)}PARENT_RESOURCES`],
      CREATE,
    });
  };
}

export function setCreateSelectedResourceTypeAction(resource) {
  return async (dispatch) => {
    try {
      dispatch(resourceTypesActions.createSelected(resource));
      return Promise.resolve(resource);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

export function setCreateSecondarySelectedResourceTypeAction(resource) {
  const { CREATE } = PERMISSIONS;
  return async (dispatch) => {
    try {
      const hasParent = _.get(resource, 'attributes.parentResourceType');
      dispatch(resourceTypesActions.createSelectedSecondary(resource));

      try {
        const hasParentPermissions =
          permissions.hasPermissions([PERMISSIONS.READ, PERMISSIONS.REFERENCE], hasParent, 'some');

        if (hasParent && hasParentPermissions) {
          await dispatch(getSelectedParentResourceAction(resource, { CREATE }));
        } else {
          await dispatch(clearSelectedParentResourceAction(CREATE));
        }
      } catch (error) {
        // Need to catch here, but error is handled below
      }
      return Promise.resolve(resource);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

export function setCreateGroupSelectedResourceTypeAction(resource) {
  return async (dispatch) => {
    try {
      dispatch(resourceTypesActions.createSelectedGroup(resource));
      return Promise.resolve(resource);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

/*
 * byType: Array[]
 * byPermission: string
 * primary: boolean
 */
export function filterResourceTypesAction(filter = {}, options = {}) {
  return async (dispatch, getState) => {
    try {
      const primary = _.get(filter, 'primary', true);
      const byType = _.get(filter, 'byType');
      const byPermission = _.get(filter, 'byPermission');
      const getParent = _.get(options, 'getParent');
      const getGroups = _.get(options, 'getGroups');
      const setCreate = _.get(options, 'setCreate');
      const CREATE = _.get(options, 'CREATE', '');
      let selected = primary
        ? _.get(getState(), 'resourceTypes.selected')
        : _.get(getState(), 'resourceTypes.secondarySelected');
      let data = _.get(getState(), 'resourceTypes.data');

      if (byType) {
        data =
          data
            .filter(resourceType => byType
              .includes(resourceType.attributes.resourceType));
      }

      if (byPermission) {
        data =
          data
            .filter(resourceType => resourceType
              .attributes
              .permissions
              .includes(byPermission));
      }

      const response = { ...filter, data };
      dispatch(resourceTypesActions.filter(response));

      if (CREATE !== '') {
        selected = _.get(getState(), 'resourceTypes.create.selected');
      }

      if (selected.id) {
        const isInFilteredData = data.find(datum => datum.id === selected.id);
        let hasParent = _.get(selected, 'attributes.parentResourceType');

        if (!isInFilteredData) {
          hasParent = _.get(data[0], 'attributes.parentResourceType');
          // eslint-disable-next-line no-unused-expressions
          primary
            ? await dispatch(setSelectedResourceTypeAction(data[0]))
            : await dispatch(setSecondarySelectedResourceTypeAction(data[0]));
          await dispatch(setCreateSelectedResourceTypeAction(data[0]));
        }

        if (isInFilteredData && setCreate) {
          await dispatch(setCreateSelectedResourceTypeAction(selected));
        }

        if (getGroups) {
          await dispatch(getSelectedGroupsResourceAction(selected, { CREATE }));
        }

        const hasParentPermissions =
          permissions.hasPermissions([PERMISSIONS.READ, PERMISSIONS.REFERENCE], hasParent, 'some');

        if (getParent && hasParent && hasParentPermissions) {
          await dispatch(getSelectedParentResourceAction(selected, { CREATE }));
        } else {
          await dispatch(clearSelectedParentResourceAction(CREATE));
        }
      }
      return Promise.resolve(response);
    } catch (error) {
      logErrorToConsole(error);
      return Promise.reject(error);
    }
  };
}

export function getReferenceAction(id, type) {
  return async (dispatch) => {
    try {
      dispatch(resourceTypesActions.referenceRequest());
      const endpoint = endpoints.endpointForResource(type, { id });
      const res = await axios.get(endpoint);
      dispatch(resourceTypesActions.referenceSuccess(res.data));
      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey } = errorMessage;
      dispatch(resourceTypesActions.referenceFailure(intlKey));
      return Promise.reject(error);
    }
  };
}

export function editReferenceAction(
  id,
  updatedAttributes,
  skipMessage = false,
  options = {},
) {
  const type = _.get(options, 'type', undefined);
  let resourceType = _.get(options, 'resourceType', undefined);
  return async (dispatch) => {
    try {
      let extraInfo = {};
      const hasNewPassword = 'userPassword' in updatedAttributes;

      if (resourceType === RESOURCE_TYPES.GENERIC) {
        const genericResource = getResourceById(type);
        resourceType = _.get(genericResource, 'attributes.displayName');
      }

      const endpoint = endpoints.endpointForResource(type, { id, password: hasNewPassword });
      const res = await axios.patch(endpoint, {
        data: {
          attributes: {
            ...updatedAttributes,
          },
        },
      });

      const resData = _.get(res, 'data.data');
      const resId = resData.id;
      const resAttributes = resData.attributes;

      if (_.isUndefined(resAttributes) || _.isUndefined(resId)) throw new Error();

      if (resId !== id) extraInfo = { ...extraInfo, newId: resId };
      if (hasNewPassword) {
        extraInfo = { ...extraInfo, hasNewPassword };
        resAttributes['dadmin-account-locked'] = [false];
      }

      const data = {
        id,
        updatedAttributes: resAttributes,
        ...extraInfo,
      };

      dispatch(resourceTypesActions.editReferenceSuccess(data));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: _.upperFirst(resourceType),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.MODEL_FORM_ERROR,
        data: { error: intlKey },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}
