// Vendors
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { withFormik } from 'formik';
import * as Yup from 'yup';

// UI-Library
import ConfirmTooltip from 'ui-library/lib/components/tooltips/ConfirmTooltip';
import Button from 'ui-library/lib/components/buttons/Button';
import PopoverMenu from 'ui-library/lib/components/tooltips/PopoverMenu';

// Components
import ResetPasswordField from '../../forms/ResetPasswordField/ResetPasswordField';

// Store
import { editUserAction } from '../../../../store/actions/userActions';
import { clearModelFormPasswordErrorAction } from '../../../../store/actions/modelForm/modelFormActions';

export const ResetPasswordTooltip = (props) => {
  const {
    // Formik-related props
    errors,
    handleBlur,
    handleChange,
    isValid,
    resetForm,
    setFieldTouched,
    setFieldValue,
    touched,
    values,

    // Component props
    children,
    disabled,
    hideTooltip,
    unlockUser,
    userId,
  } = props;
  const { attributeErrors } = useSelector(state => state.modelForm);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  /*
   * Set field back to untouched when it has a server error so we can display a server error only
   * when it hasn't been touched
   */
  useEffect(() => {
    Object.keys(attributeErrors).forEach(key => setFieldTouched(key, false));
  }, [attributeErrors, setFieldTouched]);

  const cleanUp = async () => {
    try {
      await dispatch(clearModelFormPasswordErrorAction());
      await resetForm();
      setIsLoading(false);
      setModalOpen(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSetButton = () => !(disabled || hideTooltip) && setModalOpen(true);
  const handleResetButton = () => {
    const data = {
      'ds-pwp-modifiable-state-json': { 'must-change-password': true },
    };
    dispatch(editUserAction(userId, data));
  };

  const handlEditUser = async () => {
    const data = { userPassword: values.userPassword };
    if (unlockUser) data['ds-pwp-account-disabled'] = [false];

    try {
      setIsLoading(true);
      await dispatch(editUserAction(userId, data));
      cleanUp();
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleConfirm = () => !disabled && handlEditUser();
  const handleCancel = () => cleanUp();

  const errorMessage = touched.userPassword ? errors.userPassword : '';
  let serverError;

  if (!touched.userPassword && attributeErrors.userPassword) {
    serverError = attributeErrors.userPassword.message;
  }

  const resetPasswordButton = 'components.admin-actions.reset-password-button';
  const clickElement = children
    ? <>{children}</>
    : (
      <div data-testid="reset-password-button">
        <Button inline disabled={disabled}>
          <FormattedMessage id={`${resetPasswordButton}.label`} />
        </Button>
      </div>
    );

  if (hideTooltip) return clickElement;

  return (
    <>
      <PopoverMenu
        label={clickElement}
        buttons={[
          {
              label: intl.formatMessage({
                id: `${resetPasswordButton}.buttons.set`,
              }),
              onClick: handleSetButton,
          },
          {
              label: intl.formatMessage({
                id: `${resetPasswordButton}.buttons.initiate`,
              }),
              onClick: handleResetButton,
          },
        ]}
      />
      <form className="ResetPasswordTooltip" data-testid="reset-password-form">
        <ConfirmTooltip
          buttonLabel={intl.formatMessage({
            id: 'common.save',
          })}
          buttonType="primary"
          disableSave={disabled || isLoading || !isValid}
          flags={['use-portal']}
          onCancel={handleCancel}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          onToggle={handleSetButton}
          open={modalOpen}
          placement="bottom left"
          title={intl.formatMessage({
            id: `${resetPasswordButton}.buttons.set`,
          })}
        >
          <p>
            <FormattedMessage id="components.forms.reset-password-field.modal-content" />
          </p>
          <div className="input-row">
            <ResetPasswordField
              errorMessage={errorMessage}
              onBlur={handleBlur}
              onChange={handleChange}
              password={values.userPassword}
              required
              serverError={serverError}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              userId={userId}
            />
          </div>
        </ConfirmTooltip>
      </form>
    </>
  );
};

ResetPasswordTooltip.propTypes = {
  // Formik-related props
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.objectOf(PropTypes.bool).isRequired,
  values: PropTypes.oneOfType([PropTypes.shape()]).isRequired,

  // Component props
  children: PropTypes.node,
  disabled: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  unlockUser: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

ResetPasswordTooltip.defaultProps = {
  children: null,
  disabled: false,
  hideTooltip: false,
  unlockUser: false,
};

/*
 * Warning: isInitialValid has been deprecated and will be removed in future versions of Formik
 * Use validateOnMount instead. This will run the validationSchema on mount.
 * BUG: validateOnMount does not trigger validationSchema on mount
 * https://github.com/formium/formik/issues/1950
 */

const formikProps = {
  displayName: 'ResetPasswordTooltip',
  values: { userPassword: '' },
  isInitialValid: false,
  validationSchema: Yup.object().shape({
    userPassword: Yup.string()
      .required('validation.required'),
  }),
};

export default withFormik(formikProps)(ResetPasswordTooltip);
