import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchResultItem from '../SearchResultItem/SearchResultItem';
import { PROP_MODEL_TYPES } from '../../../utils/modelTypes';

class SearchResults extends Component {
  searchAttributesObj = () => {
    const { meta } = this.props;
    return meta && Object.keys(meta.attributes)
      .filter(attr => meta.attributes[attr].searchAttribute &&
        !meta.attributes[attr].primarySearchAttribute)
      .reduce((obj, key) => ({
        ...obj,
        [key]: meta.attributes[key],
      }), {});
  };

  render() {
    const {
      handleDelete,
      messageType,
      meta,
      modelType,
      newItemId,
      primarySearchAttribute,
      queryString,
      results,
      referencePermission,
    } = this.props;
    const resultItems = results.map((result) => {
      let isExpanded = false;
      if (messageType === 'success' && result.id === newItemId) {
        isExpanded = true;
      }

      return (
        <SearchResultItem
          handleDelete={handleDelete}
          isExpanded={isExpanded}
          key={result.id}
          meta={meta}
          modelType={modelType}
          newItemId={newItemId}
          primarySearchAttribute={primarySearchAttribute}
          result={result}
          searchAttributesObj={this.searchAttributesObj()}
          referencePermission={referencePermission}
        />
      );
    });

    return (
      <>
        {
          (queryString !== undefined) &&
          <div className="result-set">
            {resultItems}
          </div>
        }
      </>
    );
  }
}

SearchResults.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  messageType: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  meta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
  }).isRequired,
  modelType: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
  newItemId: PropTypes.string,
  primarySearchAttribute: PropTypes.string,
  queryString: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
  }).isRequired).isRequired,
  referencePermission: PropTypes.bool.isRequired,
};

SearchResults.defaultProps = {
  newItemId: undefined,
  primarySearchAttribute: undefined,
};

export default SearchResults;

