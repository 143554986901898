import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-jdateformatparser';
import { useIntl, FormattedMessage } from 'react-intl';
import FormCheckbox from 'ui-library/lib/components/forms/FormCheckbox';
import FormTextField from 'ui-library/lib/components/forms/form-text-field';
import FormIntegerField from 'ui-library/lib/components/forms/form-integer-field';
import Calendar from 'ui-library/lib/components/calendars/Calendar';
import Disabled from 'ui-library/lib/components/layout/Disabled';
import FileUpload from 'ui-library/lib/components/forms/file-upload';

import ReferenceContainer from '../../../../containers/ReferenceContainer/ReferenceContainer';
import CustomAttributeFieldV2 from '../CustomAttributeFieldV2/CustomAttributeFieldV2';
import { FIELD_TYPES } from '../../../../utils/formHelpers';
import {
  setActiveExtraProperties,
  getValueCounts,
  fileToBase64,
  dispatchErrorMessage,
} from '../../../../utils/helpers';
import MultiValueField from '../MultiValueField/MultiValueField';
// Calendar errorMessage is misaligned. Overwriting some classes.
import './EditableFieldV2.css';

const EditableFieldV2 = (props) => {
  const {
    arrayHelpers,
    attr,
    attributeErrors,
    errors,
    formValues,
    meta,
    onChange,
    onValidationChange,
    onValueChange,
    readOnlyAttributes,
    resetForm,
    resourceType,
    resourceTypes,
    serverError,
    setFieldTouched,
    values,
  } = props;
  const {
    customPresentation,
    dataType,
    dateTimeFormat,
    displayName,
    extraProperties,
    multiValued,
    referenceResourceType,
    required,
  } = meta;
  const readOnly = meta.readOnly || readOnlyAttributes.includes(attr);
  const readOnlyMessage = !meta.readOnly && readOnlyAttributes.includes(attr);
  const intl = useIntl();
  const dateTimeElement = useRef(null);
  // TODO: Remove blur event listener when https://jira.pingidentity.com/browse/UIP-3449 is complete
  const dispatch = useDispatch();
  const handleCalendarOnBlur = (e) => {
    const { target } = e;
    const { name } = target;
    if (dataType === FIELD_TYPES.DATETIME) {
      values.forEach((value) => {
        if (!target.value && value !== null) {
          setFieldTouched(attr);
          onValueChange(name, target.value);
        }
      });
    }
  };

  useEffect(() => {
    const setUpEventListener = (action, eventListener) => {
      const { current } = dateTimeElement;
      if (current) {
        const calendarInputs = current.getElementsByClassName('input-calendar-value');
        if (calendarInputs) {
          Array.from(calendarInputs).forEach((calendar) => {
            if (action === 'add') {
              calendar.addEventListener(eventListener, handleCalendarOnBlur);
            } else {
              calendar.removeEventListener(eventListener, handleCalendarOnBlur);
            }
          });
        }
      }
    };

    setUpEventListener('add', 'blur');

    return () => setUpEventListener('remove', 'blur');
  });

  const handleChange = (event) => {
    setFieldTouched(attr);
    onChange(event);
  };

  const handleBlur = () => {
    setFieldTouched(attr);
  };

  const handleCustomChange = (newValue) => {
    setFieldTouched(attr);
    onValueChange(attr, newValue);
  };

  const handleIntegerChange = (newValue, name) => {
    if (newValue === '') {
      setFieldTouched(attr);
      onValueChange(name, newValue);
      return;
    }
    const parsedValue = newValue === '-' ? newValue : parseInt(newValue, 10);

    if (!FormIntegerField.isValid(newValue) && newValue !== '-') {
      return;
    }

    setFieldTouched(attr);
    onValueChange(name, parsedValue);
  };

  const handleDateChange = (newDate, name) => {
    setFieldTouched(attr);
    onValueChange(name, moment(newDate, 'MM-DD-YYYY').toISOString());
  };

  const handleValidationChange = (isValid) => {
    onValidationChange(attr, isValid);
  };

  const handleReferenceClick = (name, option) => {
    setFieldTouched(attr);
    onValueChange(name, option);
  };

  const handleOnAdd = () => {
    setFieldTouched(attr);
    arrayHelpers.push('');
  };

  const handleOnRemove = (id) => {
    arrayHelpers.remove(id);
    setFieldTouched(attr);
  };

  const allowedMIMETypes = meta.dataType === 'binary' ? Object.entries(meta.allowedMimeTypes)[0][1] : '';

  const allowedMIMETypesLabels = (meta.dataType === 'binary' ? Object.entries(meta.allowedMimeTypes)[1][1] : '')
    .split(',').map(el => el.replace('.', ' ')).toString();

  const handleOnError = (err) => {
    if (err) {
      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={err}
          values={{
             err,
            }}
        />,
      );
    }
  };


  const handleFileChange = async (e, name) => {
    const targetFile = e.target.files[0];
    const checkFileType = allowedMIMETypes.split(',').find(el => targetFile.type === el);

    if (targetFile.size > 4000000 || checkFileType === undefined) {
      return;
    }

    if (e?.target?.files) {
      const file = e.target.files[0];
      const base64File = await fileToBase64(file);
      setFieldTouched(attr);
      onValueChange(name, base64File);
    }
  };

  function handleFileRemove(e, name) {
    setFieldTouched(attr);
    onValueChange(name, '');
  }

  const createMultiValueField = field => (
    <MultiValueField
      attr={attr}
      field={field}
      handleOnAdd={handleOnAdd}
      handleOnRemove={handleOnRemove}
      readOnly={readOnly}
      resetForm={resetForm}
      rowCount={getValueCounts(extraProperties)}
    />
  );

  const getAdditionalFieldProps = (index) => {
    const fieldProps = {};
    fieldProps.key = `${attr}[${index}]`;
    fieldProps.name = `${attr}[${index}]`;

    const errorMessage = (errors[index]) ? intl.formatMessage(
      { id: errors[index] },
      setActiveExtraProperties(extraProperties, true),
    ) : '';
    fieldProps.errorMessage = serverError || errorMessage;

    if (readOnlyMessage) {
      fieldProps.labelLockText = intl.formatMessage({ id: 'custom-errors.read-only-attributes' });
    }

    return fieldProps;
  };

  const renderField = () => {
    if (customPresentation) {
      return (
        <div className="CustomField">
          <CustomAttributeFieldV2
            attr={attr}
            attributeErrors={attributeErrors}
            meta={meta}
            onValidationChange={handleValidationChange}
            onValueChange={handleCustomChange}
            resourceType={resourceType}
            value={values}
            formValues={formValues}
          />
        </div>
      );
    } else if (dataType === FIELD_TYPES.STRING) {
      const formTextField = values.map((value, index) => (
        <FormTextField
          disabled={readOnly}
          labelText={(index === 0) ? displayName : ''}
          onBlur={handleBlur}
          onChange={handleChange}
          required={required}
          value={value || ''}
          {...getAdditionalFieldProps(index)}
        />
      ));

      return (
        <div className="TextField">
          { multiValued ? createMultiValueField(formTextField) : formTextField[0] }
        </div>
      );
    } else if (dataType === FIELD_TYPES.INTEGER) {
      const formIntegerField = values.map((value, index) => {
        const fieldProps = getAdditionalFieldProps(index);
        return (
          <FormIntegerField
            disabled={readOnly}
            labelText={(index === 0) ? displayName : ''}
            min={-999999999999999}
            onBlur={handleBlur}
            onValueChange={newValue => handleIntegerChange(newValue, fieldProps.name)}
            required={required}
            value={value}
            {...fieldProps}
          />
        );
      });

      return (
        <div className="IntegerField">
          { multiValued ? createMultiValueField(formIntegerField) : formIntegerField[0] }
        </div>
      );
    } else if (dataType === FIELD_TYPES.BOOLEAN) {
      const formCheckbox = values.map((value, index) => (
        <FormCheckbox
          checked={value}
          className="inline"
          disabled={readOnly}
          label={(index === 0) ? displayName : ''}
          onBlur={handleBlur}
          onChange={handleChange}
          {...getAdditionalFieldProps(index)}
        />
      ));

      return (
        <div className="Checkbox">
          { multiValued ? createMultiValueField(formCheckbox) : formCheckbox[0] }
        </div>
      );
    } else if (dataType === FIELD_TYPES.DATETIME) {
      const toMomentFormatString = moment().toMomentFormatString(dateTimeFormat);
      const calendar = values.map((value, index) => {
        const fieldProps = getAdditionalFieldProps(index);
        return (
          <Calendar
            className="calendar"
            closeOnSelect
            date={value}
            format={toMomentFormatString}
            labelText={(index === 0) ? displayName : ''}
            labelHelpText={(index === 0) ? toMomentFormatString : ''}
            onInputTextValueChange={(val) => {
              setFieldTouched(attr);
              onValueChange(fieldProps.name, val);
            }}
            onValueChange={val => handleDateChange(val, fieldProps.name)}
            placeholder={toMomentFormatString}
            required={required}
            {...fieldProps}
          />
        );
      });

      // Calendar component has no disabled prop. Need to use <Disabled>
      if (readOnly) {
        return (
          <Disabled>{ multiValued ? createMultiValueField(calendar) : calendar[0] }</Disabled>
        );
      }

      return (
        <div className="DateTime" ref={dateTimeElement}>
          { multiValued ? createMultiValueField(calendar) : calendar[0] }
        </div>
      );
    } else if (dataType === FIELD_TYPES.REFERENCE) {
      const field = values.map((value, index) => {
        const { name } = getAdditionalFieldProps(index);
        return (
          <ReferenceContainer
            SearchDropdownProps={{
              displayName,
              handleReferenceClick,
              name,
              referenceResourceType,
              value,
            }}
            attr={attr}
            readOnly={readOnly}
            resetForm={resetForm}
            resourceType={resourceType.attributes.resourceType}
            resourceTypes={resourceTypes}
          />
        );
      });
      return (
        <div className="ReferenceField">{ field[0] }</div>
      );
      // checks whether it is a image file upload or a basic file upload
    } else if (dataType === 'binary' && meta.fileAttribute) {
      const field = values.map((value, index) => {
        const thumbnailSrc = value ? `data:image/jpg;base64,${value}` : null;
        const fieldProps = getAdditionalFieldProps(index);
        const certFileName = value?.details?.label;
        const certExpiresAt = value?.details?.expiresAt;

        if (meta.useImageSelector) {
          // eslint-disable-next-line no-plusplus
          return (
            <>
              <FileUpload
                label={index === 0 ? meta.displayName : ''}
                data-id="fileUpload"
                maxFileSizeKb={4096}
                onChange={e => handleFileChange(e, fieldProps.name)}
                onRemove={e => handleFileRemove(e, fieldProps.name)}
                onError={err => handleOnError(err)}
                labelMaxFileSize="Max Size 4MB"
                labelAcceptedFileTypes={allowedMIMETypesLabels}
                accept={allowedMIMETypes}
                labelSelect="Choose a File"
                labelRemove={values.length === 1 ? 'Remove' : ''}
                showThumbnail
                thumbnailSrc={thumbnailSrc}
                {...fieldProps}
              />
            </>
          );
        }
        return (
          <div>
            <FileUpload
              label={index === 0 ? meta.displayName : ''}
              data-id="basicUpload"
              accept={allowedMIMETypes}
              onChange={e => handleFileChange(e, fieldProps.name)}
              onRemove={e => handleFileRemove(e, fieldProps.name)}
              onError={err => handleOnError(err)}
              labelSelect="Choose a File"
              fileName={certFileName}
              labelRemove={values.length === 1 ? 'Remove' : ''}
              thumbnailSrc={thumbnailSrc}
              {...fieldProps}
            />
            {certExpiresAt ? `Expires On: ${certExpiresAt}` : ''}
          </div>
        );
      });

      return (
        <div className="FileUploadField">
          { multiValued ? createMultiValueField(field) : field[0] }
        </div>
      );
    }

    return null;
  };

  const field = renderField();
  if (!field) return null;

  return (
    <div className="EditableFieldV2">
      {field}
    </div>
  );
};

EditableFieldV2.propTypes = {
  arrayHelpers: PropTypes.oneOfType([PropTypes.shape()]).isRequired,
  attr: PropTypes.string.isRequired,
  attributeErrors: PropTypes.objectOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
    }),
  ),
  errors: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  formValues: PropTypes.shape({}).isRequired,
  meta: PropTypes.shape({
    customPresentation: PropTypes.bool.isRequired,
    dataType: PropTypes.string.isRequired,
    dateTimeFormat: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    extraProperties: PropTypes.shape({}),
    multiValued: PropTypes.bool,
    readOnly: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
    referenceResourceType: PropTypes.string,
    useImageSelector: PropTypes.bool,
    fileAttribute: PropTypes.bool,
    allowedMimeTypes: PropTypes.shape({}),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onValidationChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  readOnlyAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  resetForm: PropTypes.bool.isRequired,
  resourceType: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      attributes: PropTypes.shape({}),
      resourceEndpoint: PropTypes.string,
      resourceType: PropTypes.string,
    }),
  }).isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  serverError: PropTypes.string,
  setFieldTouched: PropTypes.func.isRequired,
  values: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.PropTypes.shape({}),
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.PropTypes.shape({}),
      PropTypes.string,
    ])),
  ]).isRequired,
  model: PropTypes.shape({}),
};

EditableFieldV2.defaultProps = {
  attributeErrors: undefined,
  serverError: '',
  model: {},
};

export default EditableFieldV2;
