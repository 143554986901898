// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import className from 'classnames';
import { injectIntl, FormattedMessage } from 'react-intl';

// UI-Library
import Messages from 'ui-library/lib/components/general/messages/Messages';
import FileDrop from 'ui-library/lib/components/forms/FileDrop';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import InputRow from 'ui-library/lib/components/layout/InputRow';
import EllipsisLoader from 'ui-library/lib/components/general/EllipsisLoader';
import Spinner from 'ui-library/lib/components/general/Spinner';

// Components
import PermissionMessage from '../Message/PermissionMessage';
import MessageContainer from '../../../../containers/MessageContainer/MessageContainer';
import ResourceTypeContainer from '../../../../containers/ResourceTypeContainer/ResourceTypeContainer';
import DropDownList from '../../list-inputs/DropDownList/DropDownList';

// Store
import { importReportAction } from '../../../../store/actions/report/report';
import {
  filterResourceTypesAction,
  setCreateSecondarySelectedResourceTypeAction,
  setCreateGroupSelectedResourceTypeAction,
} from '../../../../store/actions/resourceTypes/resourceTypes';
import { getSearchResults } from '../../../../store/actions/search/search';
import { setModalAction } from '../../../../store/actions/modal/modal';

// Utils
import permissions, { PERMISSIONS } from '../../../../utils/permissions/permissions';
import { MODEL_TYPES } from '../../../../utils/modelTypes';
import { RESOURCE_TYPES, PROP_RESOURCE_TYPES, getResourceById } from '../../../../utils/resourceTypes';
import { createDropDownOptions } from '../../../../utils/helpers';
import intlShape from '../../../../utils/intlPropType';
import locales from '../../../../locales/en.json';

// Styling
import './UploadModal.css';

export class UploadModal extends Component {
  state = {
    fields: undefined,
    file: undefined,
    selectedFile: undefined,
  };

  async componentDidMount() {
    const { model } = this.props.match.params;
    if (model === MODEL_TYPES.GROUPS) {
      this.createDropDowns([MODEL_TYPES.GROUPS, 'memberTypes', MODEL_TYPES.GENERICS]);
      await this.props.getSearchResults('', {
        skipMessage: true,
        includeGroupMeta: true,
        createSelected: true,
      });
      this.setMemberTypesDropDownOptions();
    } else {
      this.createDropDowns([MODEL_TYPES.GROUPS, MODEL_TYPES.GENERICS]);
      this.filterResourceTypes();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectedFile } = this.state;
    const {
      hasAlertSave,
      match: { params: { model } },
      search,
      selected,
      selectedGroupsResource,
      selectedParentResource,
    } = this.props;

    if (prevProps.selected !== selected) {
      if (model === MODEL_TYPES.GROUPS) {
        await this.props.getSearchResults('', {
          skipMessage: true,
          includeGroupMeta: true,
          createSelected: true,
        });
        this.setMemberTypesDropDownOptions();
      } else {
        this.filterResourceTypes();
      }
    }

    if (model === MODEL_TYPES.GROUPS) {
      if (prevProps.search !== search) {
        this.setDropDownOptions(search, MODEL_TYPES.GROUPS);
      }
    } else if (prevProps.selectedGroupsResource !== selectedGroupsResource) {
      this.setDropDownOptions(selectedGroupsResource, MODEL_TYPES.GROUPS);
    }

    if (prevProps.selectedParentResource !== selectedParentResource) {
      this.setDropDownOptions(selectedParentResource, MODEL_TYPES.GENERICS);
    }

    if (!_.isEqual(prevState.selectedFile, selectedFile)) {
      this.props.setModalAction({ dirty: !_.isUndefined(selectedFile) });
    }

    if (!_.isEqual(prevProps.hasAlertSave, hasAlertSave)) {
      this.handleAlertSave();
    }
  }

  setDropDownOptions = (props, type) => {
    const { meta, results } = props;
    const data = props.data || results;

    if (!data || data.length === 0 || !meta) {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            options: undefined,
            selectedOption: undefined,
          },
        },
      }));
      return;
    }

    const options = createDropDownOptions(data, meta);

    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          options,
          selectedOption: options[0],
        },
      },
    }));
  };

  setMemberTypesDropDownOptions = () => {
    const { data } = this.props.resourceTypes;
    const { results } = this.props.search;
    const { selectedOption } = this.state.fields.groups;
    const result = results.find(item => item.id === selectedOption.id);
    const creationMemberTypes = _.get(result, 'meta.creationMemberTypes', []);

    const filter = data
      .filter((item) => {
        const { resourceType } = item.attributes;
        const canCreateMemberTypes = creationMemberTypes.includes(item.id);
        const hasPermission = item.attributes.permissions.includes(PERMISSIONS.UPLOAD);
        return canCreateMemberTypes && hasPermission && resourceType !== RESOURCE_TYPES.GROUP;
      });
    const options = filter
      .map(item => ({
        id: item.id,
        label: item.attributes.displayName,
        type: item.attributes.resourceType,
        value: item.attributes.resourceEndpoint,
      }));

    this.setState(state => ({
      fields: {
        ...state.fields,
        memberTypes: {
          ...state.fields.memberTypes,
          options,
          selectedOption: options[0],
        },
      },
    }));

    this.props.setCreateSecondarySelectedResourceTypeAction(filter[0]);
    this.props.setCreateGroupSelectedResourceTypeAction(results[0]);
  };

  createDropDowns = (types) => {
    types.forEach((type) => {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            errorMessage: 'validation.required',
            label: `containers.model-form.${type}-dropdown-label`,
            labelPrompt: 'components.search-bar-drop-down.list-prompt',
            options: undefined,
            required: true,
            selectedOption: undefined,
            showError: false,
            type,
          },
        },
      }));
    });
  };

  async filterResourceTypes() {
    const { selected } = this.props;
    const { resourceType } = selected.attributes;
    const filter = {
      byType: [resourceType],
      byPermission: PERMISSIONS.UPLOAD,
    };
    const getParent = _.get(selected, 'attributes.parentResourceType', undefined);
    await this.props.filterResourceTypesAction(filter, {
      getParent,
      getGroups: true,
      CREATE: PERMISSIONS.CREATE,
    });
  }

  handleAlertSave = async () => {
    const { hasAlertSave, isFetching } = this.props;
    if (hasAlertSave) {
      if (isFetching) {
        await this.props.importReportAction(undefined, { cancelRequest: true });
      }
      this.handleUpload();
    }
  };

  handleUpload = async () => {
    const { closeAlertModal, closeModal, handleSave } = this.props;
    const { model } = this.props.match.params;
    const { fields, file } = this.state;
    let relationshipOptions;

    Object.keys(fields)
      .filter(key => key !== 'memberTypes')
      .forEach((key) => {
        const { selectedOption } = fields[key];
        if (selectedOption) {
          relationshipOptions = {
            ...relationshipOptions,
            [key]: selectedOption,
          };
        }
      });
    closeAlertModal();
    try {
      const res = await this.props.importReportAction(file, {
        groups: model === MODEL_TYPES.GROUPS,
        relationshipOptions,
      });
      if (res.status === 200) {
        this.props.setModalAction({ dirty: false });
        closeModal();
        handleSave();
      }
    } catch (e) {
      // Handle errors in actions
    }
  };

  handleDropDownChange = (selectedOption, type) => {
    const source = {
      memberTypes: {
        action: this.props.setCreateSecondarySelectedResourceTypeAction,
        data: this.props.resourceTypes.data,
      },
      [MODEL_TYPES.GROUPS]: {
        action: this.props.setCreateGroupSelectedResourceTypeAction,
        data: this.props.search.results,
      },
    };

    if (source[type]) {
      const resource = source[type].data.find(item => item.id === selectedOption.id);
      source[type].action(resource);
    }

    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          selectedOption,
        },
      },
    }));
  };

  handleDropDownToggle = (type) => {
    this.setState((state) => {
      const {
        required,
        selectedOption,
      } = state.fields[type];
      return ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            showError: required && !selectedOption,
          },
        },
      });
    });
  };

  render() {
    const {
      closeModal,
      intl,
      isFetching,
      match,
      messages,
      search,
      selectedParentResource,
      selectedGroupsResource,
    } = this.props;
    const { model } = match.params;
    const {
      fields,
      selectedFile,
    } = this.state;
    const {
      resourceType,
    } = this.props.selected.attributes;
    const isFetchingGroups =
      selectedGroupsResource.isRequesting ||
      search.isFetching;
    const selected = (model === MODEL_TYPES.GROUPS) ? 'secondarySelected' : 'selected';
    const { parentResourceType } = this.props[selected].attributes;
    let groupResourceType;
    if (fields) {
      if (fields.groups.selectedOption) {
        groupResourceType = fields.groups.selectedOption.type;
      }
    }

    const parentError = selectedParentResource.error;
    const {
      hasPermission,
      hasParentPermissions,
      hasGroupPermission,
    } = permissions.hasPermissionsForModal({
      parentResourceType,
      groupResourceType,
    });

    return (
      <>
        {
          !_.isEmpty(messages) &&
          <MessageContainer
            defaultMessageLayout={Messages.Layouts.BANNER}
          />
        }
        <div
          className={className(
            'upload-modal__actions',
            { resource_type: model !== MODEL_TYPES.GROUPS },
          )}
        >
          <ResourceTypeContainer
            filter={{
              byPermission: PERMISSIONS.UPLOAD,
              byType: [resourceType],
            }}
            getParent
            getGroups
            isModalOpen
            show
            showLabel
            isUploadModal
          />
          {
            (isFetchingGroups) ?
              <EllipsisLoader data-id="demo-ellipsis-loader" loading /> :
            !_.isEmpty(fields) &&
            Object.keys(fields).map((key) => {
              const {
                errorMessage,
                label,
                labelPrompt,
                options,
                required,
                selectedOption,
                showError,
                type,
                } = fields[key];

                if (
                    key === MODEL_TYPES.GENERICS &&
                    selectedParentResource.isRequesting
                  ) {
                  return (
                    <EllipsisLoader data-id="demo-ellipsis-loader" loading key={key} />
                  );
                }

                if (options && options.length > 0) {
                return (
                  <InputRow
                    key={key}
                    className={className(
                      { member_type: type === 'memberTypes' },
                    )}
                  >
                    <DropDownList
                      errorMessage={(showError) ? errorMessage : ''}
                      handleOnToggle={() => this.handleDropDownToggle(type)}
                      label={{
                        id: label,
                        displayName: _.get(getResourceById(parentResourceType), 'attributes.displayName', undefined),
                      }}
                      labelPrompt={labelPrompt}
                      handleOnChange={option => this.handleDropDownChange(option, type)}
                      options={options}
                      required={required}
                      selectedOption={selectedOption}
                    />
                  </InputRow>
                );
                }
                return null;
            })
          }
        </div>
        <Spinner show={isFetchingGroups}>
          {
            (parentError || !hasPermission) &&
            (!isFetchingGroups) ?
              <PermissionMessage
                parentError={parentError}
                hasParentPermissions={hasParentPermissions}
                hasGroupPermission={hasGroupPermission}
                parentResourceType={parentResourceType}
                groupResourceType={groupResourceType}
              />
            :
              <>
                <div className="upload-modal__helpful-hints">
                  <p><FormattedMessage id="report.upload.heading" /></p>
                  <ul>
                    {
                    Object
                    .keys(locales.report.upload.hints)
                    .map(key => <li key={key}><FormattedMessage id={`report.upload.hints.${key}`} /></li>)
                  }
                  </ul>
                </div>
                <FileDrop
                  accept={['text/csv']}
                  fileName={selectedFile}
                  onValueChange={(file) => {
                  this.setState({
                    selectedFile: file.name,
                    file,
                  });
                }}
                  onRemove={() => this.setState({ selectedFile: undefined })}
                />
                <div className="upload-modal__actions-buttons">
                  <Button
                    disabled={_.isUndefined(selectedFile) || isFetchingGroups}
                    label={intl.formatMessage({ id: 'common.upload' })}
                    loading={isFetching}
                    onClick={this.handleUpload}
                    type={buttonTypes.PRIMARY}
                  />
                  <Button
                    label={intl.formatMessage({ id: 'common.cancel' })}
                    onClick={closeModal}
                    type={buttonTypes.LINK}
                  />
                </div>
              </>
          }
        </Spinner>
      </>
    );
  }
}

UploadModal.propTypes = {
  closeAlertModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  filterResourceTypesAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  hasAlertSave: PropTypes.bool.isRequired,
  importReportAction: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      model: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  }),
  messages: PropTypes.arrayOf(PropTypes),
  resourceTypes: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes),
  }),
  selected: PropTypes.shape({
    attributes: PropTypes.shape({
      displayName: PropTypes.string,
      parentResourceType: PropTypes.string,
      resourceType: PropTypes.oneOf(PROP_RESOURCE_TYPES).isRequired,
    }).isRequired,
    id: PropTypes.string,
  }).isRequired,
  secondarySelected: PropTypes.shape({
    attributes: PropTypes.shape({
      parentResourceType: PropTypes.string,
    }),
  }),
  search: PropTypes.shape({
    isFetching: PropTypes.bool,
    results: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  selectedGroupsResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    error: PropTypes.string,
    isRequesting: PropTypes.bool,
  }),
  selectedParentResource: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({}),
    error: PropTypes.string,
    isRequesting: PropTypes.bool,
  }),
  setCreateSecondarySelectedResourceTypeAction: PropTypes.func.isRequired,
  setCreateGroupSelectedResourceTypeAction: PropTypes.func.isRequired,
  setModalAction: PropTypes.func.isRequired,
};

UploadModal.defaultProps = {
  secondarySelected: {
    attributes: {},
  },
  isFetching: false,
  match: {
    params: { type: undefined },
  },
  messages: [],
  resourceTypes: {
    data: [],
  },
  search: {
    isFetching: false,
    results: [],
  },
  selectedGroupsResource: {
    data: [],
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
  selectedParentResource: {
    data: [],
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
};

function mapStateToProps(state) {
  const { resourceTypes, search } = state;
  const { create } = state.resourceTypes;
  const meta = create.selected.attributes;

  return {
    messages: state.messageContainer.messages,
    meta,
    resourceTypes,
    search,
    selected: create.selected,
    secondarySelected: create.secondarySelected,
    selectedGroupsResource: create.selectedGroupsResource,
    selectedParentResource: create.selectedParentResource,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    filterResourceTypesAction,
    getSearchResults,
    importReportAction,
    setCreateGroupSelectedResourceTypeAction,
    setCreateSecondarySelectedResourceTypeAction,
    setModalAction,
  },
)(UploadModal));
