// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import FormDropDownList from 'ui-library/lib/components/forms/FormDropDownList';
import _ from 'lodash';

// Store
import {
  setSelectedResourceTypeAction,
  setCreateSelectedResourceTypeAction,
  setSecondarySelectedResourceTypeAction,
} from '../../store/actions/resourceTypes/resourceTypes';
import { getUserAction } from '../../store/actions/userActions';

// Styling
import './ResourceTypeContainer.css';

// Utils
import { PROP_PERMISSIONS } from '../../utils/permissions/permissions';
import { getResourceById } from '../../utils/resourceTypes';
import intlShape from '../../utils/intlPropType';

export class ResourceTypeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredResults: [],
    };

    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.checkPageforDropDownOption = this.checkPageforDropDownOption.bind(this);
  }

  componentDidMount() {
    this.checkPageforDropDownOption();
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.createSelectedResourceType?.id !== this.props.createSelectedResourceType?.id) ||
    (this.props.filteredResourceTypes !== prevProps.filteredResourceTypes)) {
      this.checkPageforDropDownOption();
    }
  }

  handleDropDownChange(option) {
    const {
      getGroups,
      getParent,
      isModalOpen,
      onFilter,
      primary,
    } = this.props;
    const { id, type } = this.props.match.params;
    const resource = getResourceById(option.value);

    if (primary) {
      if (isModalOpen) {
        this.props.setCreateSelectedResourceTypeAction(resource);
        this.props.setSelectedResourceTypeAction(resource);
      } else {
        this.props.setCreateSelectedResourceTypeAction(resource);
        this.props.setSelectedResourceTypeAction(resource, getParent, getGroups);
      }
    } else {
      this.props.setSecondarySelectedResourceTypeAction(resource);
      this.props.getUserAction(id, type, resource?.id);
    }

    if (_.isFunction(onFilter)) onFilter();
  }

  checkPageforDropDownOption() {
    const {
      filteredResourceType,
      filteredResourceTypes,
      resourceTypes,
      dropDownPage,
      isReportPage,
      selectedResourceType,
      isUploadModal,
    } = this.props;
    let filteredResults;
    if (dropDownPage === 'groupMembershipManagement') {
      filteredResults = resourceTypes
        .filter(rt => rt.attributes.resourceType === filteredResourceType);
      this.setState({ filteredResults });
    } else if (isReportPage && filteredResourceType === 'generic') {
      filteredResults = this.state.filteredResults
        .filter(type => selectedResourceType.id === type.id);
      this.setState({ filteredResults });
    } else if (isUploadModal) {
      filteredResults = this.state.filteredResults
        .filter(type => type.id === selectedResourceType.id);
      this.setState({ filteredResults });
    } else {
      filteredResults = filteredResourceTypes || resourceTypes;
      this.setState({ filteredResults });
    }
  }

  render() {
    const {
      intl,
      isModalOpen,
      primary,
      secondarySelectedResourceType,
      selectedResourceType,
      createSelectedResourceType,
      show,
      showLabel,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const selected = (isModalOpen) ? createSelectedResourceType : selectedResourceType;
    const options = this.state.filteredResults.map(resourceType => ({
      label: resourceType.attributes.displayName,
      value: resourceType.id,
      key: resourceType.id,
    }));

    if (options.length < 1 && !show) return null;

    let selectedOption;
    if (primary && selected) {
      selectedOption = {
        label: selected.attributes.displayName,
        value: selected.id,
      };
    } else if (!primary && secondarySelectedResourceType) {
      selectedOption = {
        label: secondarySelectedResourceType.attributes.displayName,
        value: secondarySelectedResourceType.id,
      };
    } else {
      [selectedOption] = options;
    }

    return (
      <div className="ResourceTypeContainer">
        <FormDropDownList
          flags={['use-portal', 'p-stateful']}
          label={
            showLabel
              ? intl.formatMessage({ id: 'components.search-bar-drop-down.label' })
              : undefined
          }
          labelPrompt={intl.formatMessage({ id: 'components.search-bar-drop-down.list-prompt' })}
          onValueChange={this.handleDropDownChange}
          options={options}
          required
          searchType={FormDropDownList.SearchTypes.BOX}
          selectedOption={selectedOption}
          title={selectedOption.label}
        />
      </div>
    );
  }
}

ResourceTypeContainer.propTypes = {
  filter: PropTypes.shape({
    byType: PropTypes.arrayOf(PropTypes.string),
    byPermission: PropTypes.oneOf(PROP_PERMISSIONS),
  }),
  filteredResourceType: PropTypes.string,
  filteredResourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  })),
  getGroups: PropTypes.bool,
  getParent: PropTypes.bool,
  intl: intlShape.isRequired,
  isModalOpen: PropTypes.bool,
  onFilter: PropTypes.func,
  primary: PropTypes.bool,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  })),
  secondarySelectedResourceType: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      resourceType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
  selectedResourceType: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      resourceType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
  createSelectedResourceType: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      resourceType: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    }).isRequired,
  }),
  setSecondarySelectedResourceTypeAction: PropTypes.func.isRequired,
  setSelectedResourceTypeAction: PropTypes.func.isRequired,
  setCreateSelectedResourceTypeAction: PropTypes.func.isRequired,
  show: PropTypes.bool,
  showLabel: PropTypes.bool,
  dropDownPage: PropTypes.string,
  isReportPage: PropTypes.bool,
  isUploadModal: PropTypes.bool,
  getUserAction: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ResourceTypeContainer.defaultProps = {
  filter: undefined,
  filteredResourceType: undefined,
  filteredResourceTypes: undefined,
  getGroups: false,
  getParent: false,
  isModalOpen: false,
  onFilter: undefined,
  primary: true,
  resourceTypes: [],
  secondarySelectedResourceType: undefined,
  selectedResourceType: undefined,
  createSelectedResourceType: undefined,
  show: false,
  showLabel: false,
  dropDownPage: '',
  isReportPage: false,
  isUploadModal: false,
};

function mapStateToProps(state) {
  const {
    data,
    selected,
    secondarySelected,
    create,
    filter,
  } = state.resourceTypes;

  return {
    resourceTypes: data,
    selectedResourceType: selected,
    secondarySelectedResourceType: secondarySelected,
    createSelectedResourceType: create.selected,
    filteredResourceType: filter && filter.byType[0],
    filteredResourceTypes: filter && filter.data,
  };
}

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    setSelectedResourceTypeAction,
    setSecondarySelectedResourceTypeAction,
    setCreateSelectedResourceTypeAction,
    getUserAction,
  },
)(ResourceTypeContainer)));
