import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import permissions from '../../../utils/permissions/permissions';
import { GROUPS_SEARCH_ROUTE, USERS_SEARCH_ROUTE } from '../../../utils/routes';

/**
 * Higher order component used to hide a component if a user is signed in.
 * Will redirect the user to the signed in splash page.
 * Useful for showing components that only should be seen by guest users.
 */

export class NotAuthentication extends Component {
  componentDidMount() {
    const { authenticated, history, usersPermission } = this.props;

    if (authenticated) {
      history.replace(usersPermission ? USERS_SEARCH_ROUTE : GROUPS_SEARCH_ROUTE);
    }
  }

  componentDidUpdate() {
    const { authenticated, history, usersPermission } = this.props;

    if (authenticated) {
      history.replace(usersPermission ? USERS_SEARCH_ROUTE : GROUPS_SEARCH_ROUTE);
    }
  }

  render() {
    const ComposedComponent = this.props.composedComponent;
    return this.props.authenticated ? null : <ComposedComponent {...this.props} />;
  }
}

NotAuthentication.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  authenticated: PropTypes.bool.isRequired,
  composedComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  usersPermission: PropTypes.bool.isRequired,
};

export default function NoRequireAuth(ComposedComponent) {
  function mapStateToProps(state) {
    return {
      authenticated: state.oidc.user !== null
        && !state.oidc.user.expired
        && !state.oidc.isLoadingUser
        && !!state.authentication.isAuthenticated,
      composedComponent: ComposedComponent,
      usersPermission: permissions.manageUsers(),
    };
  }

  return connect(mapStateToProps)(NotAuthentication);
}
