import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import _ from 'lodash';
import SearchDropdown from '../../components/shared/list-inputs/SearchDropdown/SearchDropdown';
import ModalWithButton from '../../components/shared/modals/ModalWithButton/ModalWithButton';
import EditModal from '../../components/shared/modals/EditModal/EditModal';
import { getSearchResults } from '../../store/actions/search/search';
import {
  getTitleOrPrimaryAttributeKey,
  referenceSearchAttrs,
  removeKeyValue,
  setReferenceDropDownOptions,
} from '../../utils/helpers';
import permissions, { PERMISSIONS } from '../../utils/permissions/permissions';
import intlShape from '../../utils/intlPropType';
import { setModelFormAction } from '../../store/actions/modelForm/modelFormActions';

export class ReferenceContainer extends Component {
  state = {
    inputProps: {},
    reference: undefined,
  };

  componentDidMount() {
    this.setInputProps();
  }

  componentDidUpdate() {
    const {
      resetForm,
      SearchDropdownProps: { value },
    } = this.props;

    if (resetForm) {
      if (value) {
        this.handleReferenceSearch(value.label, resetForm);
      }
    }
  }

  setInputProps = () => {
    const {
      intl,
      readOnly,
      resourceTypes,
      SearchDropdownProps,
    } = this.props;
    const {
      referenceResourceType,
    } = SearchDropdownProps;
    const inputProps = {};
    const [reference] = resourceTypes.filter(type => type.id === referenceResourceType);

    if (!reference || readOnly) {
      inputProps.disabled = true;

      if (!reference) {
        inputProps.labelHelpText =
        intl.formatMessage(
          { id: 'components.drop-down.reference.empty' },
        );
      }
    } else {
      const referenceAttrs = reference.attributes.attributes;
      const placeholderAttrs = referenceSearchAttrs(referenceAttrs)
        .map(searchAttr => referenceAttrs[searchAttr].displayName)
        .join(', ');

      inputProps.disabled = false;
      inputProps.searchBoxPlaceholder =
        intl.formatMessage(
          { id: 'components.drop-down.reference.placeholder' },
          { attributes: placeholderAttrs },
        );
    }

    this.setState({ inputProps, reference });
  };

  handleReferenceClick = (name, option) => {
    const {
      attr, errors, isModalOpen, results,
    } = this.props;

    let result;
    if (option && results) {
      [result] = results.filter(item => item.id === option.id);
    }

    if (!isModalOpen) {
      this.props.setModelFormAction({ reference: { errors: removeKeyValue(errors, attr) } });
    }

    this.props.SearchDropdownProps.handleReferenceClick(name, option, result);
  };

  handleReferenceSearch = (searchString, resetForm) => {
    const { attr, errors } = this.props;
    const { referenceResourceType, value } = this.props.SearchDropdownProps;
    const reference = (resetForm) ? value : null;
    this.props.getSearchResults(searchString,
      {
        resourceTypeId: referenceResourceType,
        REFERENCE: 'REFERENCE',
        reference,
        attr,
        errors,
      },
    );
  };

  handleSave = (res) => {
    const { data: { id, attributes, type }, meta } = res;
    const { name } = this.props.SearchDropdownProps;
    const primaryAttr = getTitleOrPrimaryAttributeKey(meta);
    const option = { id, label: attributes[primaryAttr][0], type };
    this.handleReferenceClick(name, option);
  };

  render() {
    const { inputProps, reference } = this.state;
    const {
      attr,
      errors,
      intl,
      isModalOpen,
      resourceType,
      resourceTypes,
      results,
      SearchDropdownProps,
    } = this.props;
    const {
      displayName,
      name,
      referenceResourceType,
      value,
      labelHelpText,
    } = SearchDropdownProps;

    const error = (errors[attr] || false) && !isModalOpen;
    const hasPermission = permissions.hasPermission(PERMISSIONS.UPDATE, value && value.type);
    const showIcon = !_.isNull(value) && hasPermission && !error && !isModalOpen;
    const errorMessage = error &&
      intl.formatMessage(
        { id: 'components.drop-down.reference.non-existing' },
        { displayName: displayName.toLowerCase() },
      );

    if (reference) {
      const referenceAttrs = reference.attributes.attributes;
      inputProps.options = setReferenceDropDownOptions(results, referenceAttrs);
    }

    return (
      <>
        <SearchDropdown
          labelText={intl.formatMessage(
            { id: 'components.drop-down.reference.label' },
            { displayName },
          )}
          labelHelpText={labelHelpText || intl.formatMessage(
            { id: 'components.drop-down.reference.helpful-tip' },
            { displayName: displayName.toLowerCase() },
          )}
          displayName={displayName}
          errorMessage={errorMessage || ''}
          handleReferenceClick={this.handleReferenceClick}
          handleReferenceSearch={this.handleReferenceSearch}
          name={name}
          referenceResourceType={referenceResourceType}
          value={value}
          {...inputProps}
        />
        <ModalWithButton
          buttonProps={{
            className: 'referenceField__edit-button',
            iconName: 'edit',
            inline: true,
          }}
          modalTitle={{
            id: 'components.modal.title',
            title: 'Edit',
          }}
          showButton={showIcon}
        >
          <EditModal
            handleSave={this.handleSave}
            resourceType={resourceType}
            resourceTypes={resourceTypes}
            selected={reference}
            value={value}
          />
        </ModalWithButton>
      </>
    );
  }
}

ReferenceContainer.propTypes = {
  attr: PropTypes.string,
  errors: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  resetForm: PropTypes.bool.isRequired,
  resourceType: PropTypes.string.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  SearchDropdownProps: PropTypes.shape({
    displayName: PropTypes.string,
    handleReferenceClick: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    referenceResourceType: PropTypes.string.isRequired,
    value: PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
    labelHelpText: PropTypes.string,
  }).isRequired,
  setModelFormAction: PropTypes.func.isRequired,
};

ReferenceContainer.defaultProps = {
  attr: '',
};

function mapStateToProps(state) {
  const { results } = state.search.reference;
  const { reference: { errors } } = state.modelForm;
  const { open } = state.modal;

  return {
    errors,
    isModalOpen: open,
    results,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    getSearchResults,
    setModelFormAction,
  },
)(ReferenceContainer));
