/* eslint-disable prefer-destructuring */
/* eslint-disable no-plusplus */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { FormattedMessage } from 'react-intl';
import Messages from 'ui-library/lib/components/general/messages/index';
import StatusIndicator from 'ui-library/lib/components/general/StatusIndicator';
import { FIELD_TYPES, EXTRA_PROPERTIES } from './formHelpers';

const defaultSuccessTimeoutMs = 3000;
const defaultTimeoutMs = 5000;

// dispatch a message to the Messages reducer
const dispatchMessage = (
  dispatch,
  message,
  type = Messages.MessageTypes.ERROR,
  timeoutMs = defaultTimeoutMs,
) => {
  Messages.Actions.addMessage(message, type, timeoutMs)(dispatch);
};

export const dispatchErrorMessage = (dispatch, message, timeoutMs = defaultTimeoutMs) => {
  dispatchMessage(dispatch, message, Messages.MessageTypes.ERROR, timeoutMs);
};

export const dispatchSuccessMessage = (dispatch, message, timeoutMs = defaultSuccessTimeoutMs) => {
  dispatchMessage(dispatch, message, Messages.MessageTypes.SUCCESS, timeoutMs);
};

export const dispatchWarningMessage = (dispatch, message) => {
  dispatchMessage(dispatch, message, Messages.MessageTypes.NOTICE, 10000);
};

export const dispatchInfoMessage = (dispatch, message, timeoutMs = defaultTimeoutMs) => {
  dispatchMessage(dispatch, message, Messages.MessageTypes.FEATURE, timeoutMs);
};

export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    const newMessages = messages;

    if (typeof value === 'string') {
      newMessages[prefixedKey] = value;
    } else {
      Object.assign(newMessages, flattenMessages(value, prefixedKey));
    }

    return newMessages;
  }, {});
}

export const HTTP_ERRORS = {
  401: 'http.error.401-unauthorized',
  403: 'http.error.403-forbidden',
  404: 'http.error.404-not-found',
  422: 'http.error.422-unprocessable-entity',
  500: 'http.error.500-internal-server-error',
  0: 'http.error.default',
};

export function generateHttpErrorMessage(error) {
  const errorTitle = _.get(error, 'response.data.errors[0].title', undefined);
  let statusCode = _.get(error, 'response.status', 0);
  let intlKey = HTTP_ERRORS[statusCode] || HTTP_ERRORS[0];

  if (errorTitle && _.isUndefined(HTTP_ERRORS[statusCode])) {
    statusCode = errorTitle;
    intlKey = 'http.error.title';
  }

  return { intlKey, statusCode };
}

export function sortData(data, sortBy) {
  return _.orderBy(
    data.slice(),
    [`attributes.${sortBy.key}[0]`],
    [sortBy.order],
  );
}

export const blacklistedDisplayAttrs = [
  'ds-pwp-account-disabled',
  'ds-pwp-state-json',
  'ds-pwp-modifiable-state-json',
  'dadmin-account-locked',
  'dadmin-is-dynamic-group',
];

export const blacklistedSelectedFieldsAttrs = [
  'userPassword',
  'ds-pwp-state-json',
  'ds-pwp-modifiable-state-json',
];

export function getNonBlacklistedAttributes(
  metaAttributes,
  attributes,
  blacklist = blacklistedDisplayAttrs,
) {
  return Object
    .entries(metaAttributes)
    .filter(([key]) => (
      Object.keys(attributes).indexOf(key) >= 0 && blacklist.indexOf(key) < 0
    ));
}

export function getNonBlacklistedSelectedFieldsAttributes(
  attributes,
) {
  return Object
    .entries(attributes)
    .filter(([, value]) => !value.isCorrelatedResource && value.dataType !== 'binary')
    .reduce((result, [key, val]) => {
      if (blacklistedSelectedFieldsAttrs.indexOf(key) >= 0) return result;
      return { ...result, [key]: val };
    }, {});
}

export function setDropDownMultiOptions(
  attributes,
) {
  return Object
    .entries(attributes)
    .filter(([key]) => blacklistedSelectedFieldsAttrs.indexOf(key) < 0)
    .map(([key, value], index) => ({
      id: index + 1,
      attr: key,
      name: value.displayName,
    }));
}

export function setDropDownOptions(
  attributes,
) {
  return Object
    .entries(attributes)
    .map(([key, value], index) => {
      const result = {
        dataType: value.dataType,
        id: index + 1,
        label: value.displayName,
        value: key,
      };
      if (value.dataType === FIELD_TYPES.DATETIME) {
        result.dateTimeFormat = value.dateTimeFormat;
      }
      return result;
    });
}

export function getTitleAttributeKey(meta) {
  return meta && Object.keys(meta.attributes)
    .filter(attr => meta.attributes[attr].isTitleAttribute === true)[0];
}

export function getPrimarySearchAttributeKey(meta) {
  return meta && Object.keys(meta.attributes)
    .filter(attr => meta.attributes[attr].primarySearchAttribute === true)[0];
}

export function isTest() {
  try {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'test';
  } catch (e) {
    return false;
  }
}

export function logErrorToConsole(error) {
  /* eslint-disable-next-line no-console */
  if (!isTest()) console.error(_.get(error, 'response.data.errors[0].title', error));
}

export function getTitleOrPrimaryAttributeValue(attrs, meta) {
  try {
    const titleAttr = getTitleAttributeKey(meta);
    const primarySearchAttr = getPrimarySearchAttributeKey(meta);
    const displayAttr = titleAttr || primarySearchAttr;
    let displayValue;

    if (displayAttr) displayValue = _.get(attrs, `[${displayAttr}][0]`, '');
    if (!displayValue) {
      const metaAttrs = meta && Object.keys(meta.attributes);
      const searchAttrs = metaAttrs.filter(attr => meta.attributes[attr].searchAttribute === true);
      const existentAttr = searchAttrs.find((attr) => {
        const hasProperty = ({}.hasOwnProperty.call(attrs, attr));
        if (!hasProperty) return false;
        return !!attrs[attr][0];
      });
      return _.get(attrs, `[${existentAttr}][0]`, '');
    }

    return displayValue;
  } catch (error) {
    logErrorToConsole(error);
    return '';
  }
}

export function createDropDownOptions(data, meta) {
  return data.map(item => ({
    id: item.id,
    label: getTitleOrPrimaryAttributeValue(item.attributes, meta),
    type: item.type,
    value: item.id,
  })).filter(item => item.label !== '');
}

export function getTitleOrPrimaryAttributeKey(meta) {
  const titleAttr = getTitleAttributeKey(meta);
  const primarySearchAttr = getPrimarySearchAttributeKey(meta);
  return titleAttr || primarySearchAttr;
}

export function referenceSearchAttrs(attributes) {
  const attrs = [getTitleOrPrimaryAttributeKey({ attributes })];
  const titleOrPrimaryAttr = getTitleOrPrimaryAttributeKey({ attributes });
  const metaAttrs = Object.entries(attributes);

  for (let i = 0; i < metaAttrs.length; i++) {
    const key = metaAttrs[i][0];
    const value = metaAttrs[i][1];
    if (attrs.length >= 3) { break; }
    if (value.searchAttribute && key !== titleOrPrimaryAttr) {
      attrs.push(key);
    }
  }
  return attrs;
}

export function setReferenceDropDownOptions(
  results,
  attributes,
) {
  const searchAttrs = referenceSearchAttrs(attributes);
  return results.map(item => searchAttrs.reduce((result, current, index) => {
    const label = item.attributes[current] ? item.attributes[current][0] : undefined;

    if (index > 0) {
      return {
        ...result,
        [`subLabel${index}`]: label,
      };
    }

    return {
      ...result,
      label,
      value: item.id,
    };
  }, {}));
}

export function getPrimarySearchDisplayName(meta, parentResourceType) {
  const resourceTypeMatch = meta && meta.included[parentResourceType];
  return Object.keys(resourceTypeMatch.attributes.attributes)
    .filter(attr => resourceTypeMatch.attributes.attributes[attr]
      .primarySearchAttribute === true)[0];
}

export function displayPrimaryAttr(attrs, primaryKey, meta) {
  const userHasPrimaryAttributeValue = {}.hasOwnProperty.call(attrs, primaryKey);

  if (userHasPrimaryAttributeValue === false) {
    const metaAttrs = meta && Object.keys(meta.attributes);
    const searchAttrs = metaAttrs.filter(attr => meta.attributes[attr].searchAttribute === true);
    const existentAttr = searchAttrs.find(attr => ({}.hasOwnProperty.call(attrs, attr)));
    return _.get(attrs, `[${existentAttr}][0]`, '');
  }

  return _.get(attrs, `[${primaryKey}][0]`, '');
}

export async function checkURLResponse(path) {
  try {
    const response = await axios.get(path);
    return response;
  } catch (err) {
    return null;
  }
}

export function isUserPasswordCategorized(attributes) {
  let result = true;
  attributes.forEach(([key, value]) => {
    if (key === 'userPassword') {
      if (typeof value.category === 'undefined') {
        result = false;
      }
    }
  });
  return result;
}

export function categorizeAttributes(categories, attributes, intl, summary) {
  if (categories && categories.length) {
    let attrs = attributes;
    if (summary) {
      attrs = attributes.filter(([, value]) => (value.includeInSummary));
    }
    const undefinedCategoryAttributes = attrs
      .filter(([key, value]) => (
        typeof value.category === 'undefined' &&
        key !== 'userPassword'
      ));
    const categorizedAttrs = [];
    categories.forEach((category) => {
      const categoryObject = {
        name: category,
        attributes: attrs
          .filter(([, value]) => value.category === category)
          .map(([key, value]) => ({ name: key, value })),
      };

      if (categoryObject.attributes.length > 0) {
        categorizedAttrs.push(categoryObject);
      }
    });

    if (undefinedCategoryAttributes.length > 0) {
      const uncategorizedAttrsObject = {
        name: intl.formatMessage({ id: 'containers.model-form.misc-attributes' }),
        attributes: undefinedCategoryAttributes
          .map(([key, value]) => ({ name: key, value })),
      };

      categorizedAttrs.push(uncategorizedAttrsObject);
    }

    return categorizedAttrs;
  }

  return [];
}

/**
 * Returns an object which maps each errored attribute as a key and information about that error
 * as the value for that key.
 *
 * @param {{}} [error] An object from the server response containing an array of attribute errors
 * @returns {object} object mapping attributes to an error object
 */
export function generateAttributeErrors(error) {
  const regexp = /^\/data\/attributes\/(.*)/;
  const errors = _.get(error, 'response.data.errors', []);
  const attributeErrors = {};

  errors.forEach((e, index) => {
    const key = _.get(e, 'source.pointer', undefined);

    if (key) {
      const attribute = regexp.exec(key)[1];
      const { title } = errors[index];
      attributeErrors[attribute] = { message: title };
    }
  });

  return attributeErrors;
}

export const getDataPair = (attrValue, attrMeta) => {
  const { dataType } = attrMeta;
  let value = attrValue;

  // The 'complex' dataType means it is a JSON object - this is based on SCIM API implementation
  if (dataType === 'complex') {
    value = value.map(el => JSON.stringify(el));
  } else if (dataType === 'reference') {
    value = value.map(el => String(el.label));
  } else {
    value = value.map(el => String(el));
  }

  return {
    label: attrMeta.displayName,
    value: value.join(', '),
  };
};

export const isDynamicGroup = group => _.get(group, 'attributes[dadmin-is-dynamic-group][0]', false);
export const hasExceededMemberLimit = data => (
  _.get(data, 'meta.directMembers.sizeLimitExceeded', false)
  || _.get(data, 'meta.indirectMembers.sizeLimitExceeded', false)
);
export const hasExceededNonMemberLimit = data => _.get(data, 'meta.nonMembers.sizeLimitExceeded', false);

export function convertStringToArray(value) {
  return (_.isArray(value)) ? value : [value];
}

export const setActiveExtraProperties = (extraProps, toString) => Object.entries(extraProps)
  .reduce((result, [key, value]) => {
    if (value) {
      if (toString) {
        return {
          ...result,
          [_.camelCase(key)]: (value.length > 1) ? value.join(', ') : value[0],
        };
      }

      return {
        ...result,
        [_.camelCase(key)]: value,
      };
    }
    return { ...result };
  }, {});

export const getValueCounts = extraProperties => Object.entries(EXTRA_PROPERTIES)
  .reduce((result, [, property]) => {
    if (extraProperties[property]) {
      return {
        ...result,
        [property]: Number(extraProperties[property][0]),
      };
    }
    return { ...result };
  }, {});

// Remove function and styling once https://jira.pingidentity.com/browse/UIP-3610 is done
export const formatBodyData = data => data
  .map(arr => arr.map((item) => {
    if (Array.isArray(item)) {
      return item.join('\n');
    }
    return item;
  }));

export const setFilterTypeAttrs = attributes => Object
  .entries(attributes)
  .reduce((result, [key, val]) => {
    if (!val.filterAttribute) return result;
    return { ...result, [key]: val };
  }, {});

export const generateScimFilter = (filterType) => {
  const { selected, value } = filterType;
  const { dataType } = selected;
  const attr = selected.value;
  const fieldTypes = {
    string: {
      opertor: 'eq',
      val: `"${value}"`,
    },
    dateTime: {
      opertor: 'sw',
      val: `"${value}T"`,
    },
    integer: {
      opertor: 'eq',
      val: value,
    },
    boolean: {
      opertor: 'eq',
      val: value,
    },
  };
  let uri;

  if (filterType.value === '') {
    uri = `not (${attr} pr)`;
  } else {
    const { opertor, val } = fieldTypes[dataType];
    uri = `${attr} ${opertor} ${val}`;
  }
  return uri;
};

export const createStatusLabel = (item) => {
  const label = _.replace(item, /-/g, ' ');
  return _.capitalize(label);
};

const insert = (str, index, value) => str.substr(0, index) + value + str.substr(index);

export const createStatusMessage = message => message
  .split(' ')
  .map((item) => {
    if (item.length === 19 && item.includes('Z')) {
      const newTimeStamp = insert(item, 8, 'T');
      return `${moment(newTimeStamp).format('YYYY-MM-DD HH:mm:ss')}`;
    }
    return item;
  })
  .join(' ');

export const createStatusIcon = (status) => {
  if (status.includes('expired')) {
    return 'ERROR';
  } else if (status.includes('locked')) {
    return 'NOTICE';
  }
  return 'EMPTY';
};

const parseFileName = contentDisposition => contentDisposition
  .split(';')
  .find(n => n.includes('filename='))
  .split('=')
  .pop()
  .replace(/(^"|"$)/g, '');

export const createDownloadFile = (res, defaultFileName) => {
  const { data, headers } = res;
  const filename = headers['content-disposition'] ? parseFileName(headers['content-disposition']) : null;
  const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
  const link = document.createElement('a');
  link.href = url;
  link.download = filename || defaultFileName;
  link.click();
  window.URL.revokeObjectURL(url);
  return link;
};

export const getAccountInfoDataPair = ({
  label, icon, message, value,
}) => ({
  label,
  value: (
    <StatusIndicator type={StatusIndicator.statusTypes[icon]}>
      <FormattedMessage id={message} values={{ value }} />
    </StatusIndicator>
  ),
});

export const convertTimestampToDate = (value) => {
  const isDateValid = (new Date(value)).getTime() > 0 && (typeof (value) === 'string');

  if (isDateValid) {
    return `${moment(value).format('YYYY-MM-DD HH:mm:ss')}`;
  }

  return value;
};

export const getAccountInfoValue = (item, val) => {
  let value = convertTimestampToDate(val);

  if (item === 'password-policy-dn') {
    [value] = _.replace(value, /cn=/g, '').split(',');
  }

  return value;
};

export const getLabel = label => label
  .split('-')
  .map(item => _.capitalize(item))
  .join(' ');

/*
 * TODO: Remove buttom once https://jira.pingidentity.com/browse/DEVHELP-14693 is completed
 * export const removeKeyValue = (obj = {}, attr) => Object.fromEntries(
 *   Object.entries(obj).filter(([key]) => key !== attr),
 * );
 */

export const removeKeyValue = (obj, attr) => Object
  .entries(obj).reduce((result, [key, value]) => {
    if (key === attr) {
      return { ...result };
    }

    return {
      ...result,
      [key]: value,
    };
  }, {});

const findResourceType = (item, resourceTypes) => resourceTypes
  .filter(resourceType => resourceType.id.toLowerCase() ===
    item.correlatedResourceType.toLowerCase())
  .map(obj => obj && obj.attributes.displayName)
  .toString();

const findEntryDisplayName = (model, resourceTypes) => resourceTypes
  .filter(resourceType => resourceType.id.toLowerCase() ===
    model.type.toLowerCase())
  .map(obj => obj && obj.attributes.displayName)
  .toString();

const formatDisplayValue = (attribute, value = '') => {
  const { dataType, dateTimeFormat = null } = attribute;
  const returnedValue = (dataType === 'dateTime')
    ? moment(value).formatWithJDF(dateTimeFormat)
    : value.toString();
  return returnedValue;
};

export const correlatedResourceFormatter = (model, modelMeta, resourceTypes) => Object
  .entries(model.attributes)
  .map(([key, val]) => [key, {
    id: key,
    model: val,
    includedKeys: Object.entries(modelMeta.included)
      .map(([_key, _val]) => [_key, Object
        .entries(_val.attributes.attributes)
        .filter(([, __val]) => __val.includeInSummary),
      ])
      .filter(([_key]) => val[0]?.type === _key)
      .map(([, _val]) => _val)
      .reduce((_acc, _val) => _acc.concat(_val), [])
      .reduce((_acc, [objKey, _val]) => ({
        ..._acc,
        [objKey]: _val,
      }), {}),
    ...modelMeta.attributes[key],
  }])
  .filter(([, val]) => val.isCorrelatedResource)
  .map(([key, val]) => [key, {
    ...val,
    title: val.displayName,
    linkedResourceType: findResourceType(val, resourceTypes),
    currentEntry: findEntryDisplayName(model, resourceTypes),
    correlatedResources: val.model.map(modelItem => ({
      title: modelItem.label,
      id: modelItem.id,
      attributes: modelItem.attributes,
      includeInSummaryAttributes:
        Object.entries(modelItem.attributes)
          .filter(([_modelItem]) => Object.keys(val.includedKeys)
            .includes(_modelItem))
          .map(([_key, _val]) => ({ key: _key, val: formatDisplayValue(val.includedKeys[`${_key}`], _val.join(', ')), displayName: val.includedKeys[`${_key}`].displayName }),
          ),
    })),
  }])
  .map(([, val]) => val);

export const fileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const dataUrl = reader.result;
    const tokens = dataUrl.split(';base64,');
    resolve(tokens[1]);
  };
  reader.onerror = e => reject(e);
});
