import axios from 'axios';
import userManager from './userManager';

const { DS_HOST, DS_PORT, DADMIN_API_NAMESPACE } = window;
const API_NAMESPACE = DADMIN_API_NAMESPACE || 'dadmin/v2';

function baseURL() {
  if (DS_HOST && DS_PORT) {
    return `https://${DS_HOST}:${DS_PORT}/${API_NAMESPACE}/`;
  }

  return `/${API_NAMESPACE}/`;
}

const instance = axios.create({
  baseURL: baseURL(),
  headers: {
    'Content-Type': 'application/vnd.api+json',
  },
});

instance.interceptors.request.use(async (config) => {
  // Update access token before each request is sent
  const configWithAuth = config;
  const user = await userManager.getUser();
  if (user) configWithAuth.headers.Authorization = `${user.token_type} ${user.access_token}`;
  return configWithAuth;
});

export default instance;
