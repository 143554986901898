import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { FormattedMessage } from 'react-intl';
import PageSpinner from 'ui-library/lib/components/general/PageSpinner';

import permissions from '../../../utils/permissions/permissions';
import userManager from '../../../utils/userManager';
import {
  BASE_ROUTE,
  USERS_SEARCH_ROUTE,
  GROUPS_SEARCH_ROUTE,
  NOT_FOUND_ROUTE,
  SIGNOUT_ROUTE,
} from '../../../utils/routes';
import {
  getResourceTypesAction,
  setSelectedResourceTypeAction,
  setSecondarySelectedResourceTypeAction,
} from '../../../store/actions/resourceTypes/resourceTypes';
import {
  authCallbackErrorAction,
  setIsAuthenticatedAction,
} from '../../../store/actions/authentication/authentication';
import {
  RESOURCE_TYPES,
  getFirstResourceOfType,
  getResourceByURL,
  getResourceTypes,
} from '../../../utils/resourceTypes';

export class CallbackPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: undefined,
      route: undefined,
    };
  }
  // this page is specific for errors occuring with OIDC
  onCallbackSuccess = async () => {
    try {
      await this.props.getResourceTypesAction();
      const resourceTypes = getResourceTypes();
      const { usersPermission, groupsPermission, referencePermission } = this.props;
      const userResource = getFirstResourceOfType(RESOURCE_TYPES.USER);
      const groupResource = getFirstResourceOfType(RESOURCE_TYPES.GROUP);
      const initialURL = localStorage.getItem('initialURL');
      const initialURLResourceType = (initialURL) ? getResourceByURL(initialURL) : undefined;

      if (initialURLResourceType && usersPermission && groupsPermission) {
        await this.props.setSelectedResourceTypeAction(initialURLResourceType);
      } else if (userResource && usersPermission) {
        await this.props.setSelectedResourceTypeAction(userResource);
      } else if (groupResource && !usersPermission && groupsPermission) {
        await this.props.setSelectedResourceTypeAction(groupResource);
      }
      if (groupResource && usersPermission && (groupsPermission || referencePermission)) {
        await this.props.setSecondarySelectedResourceTypeAction(groupResource);
      }

      await this.props.setIsAuthenticatedAction();

      let redirect;

      if (resourceTypes.length > 0) {
        if (initialURL) {
          redirect = initialURL;
        } else {
          redirect = usersPermission ? USERS_SEARCH_ROUTE : GROUPS_SEARCH_ROUTE;
        }
      } else {
        redirect = NOT_FOUND_ROUTE;
        this.setState({
          ...this.state,
          route: {
            ...this.state.route,
            path: SIGNOUT_ROUTE,
            message: 'page.no-resources.action-sentence',
          },
        });
      }

      this.setState({ redirect });
    } catch (error) {
      this.onCallbackError(error);
    }
  };

  onCallbackError = (error) => {
    this.props.authCallbackErrorAction(error);
    this.setState({ redirect: BASE_ROUTE });
  };

  render() {
    const { redirect, route } = this.state;

    if (redirect) {
      const path = {
        pathname: redirect,
        state: route,
      };

      return <Redirect to={path} />;
    }

    return (
      <div className="CallbackPage">
        <CallbackComponent
          userManager={userManager}
          successCallback={this.onCallbackSuccess}
          errorCallback={this.onCallbackError}
        >
          <PageSpinner show>
            <FormattedMessage id="auth.callback-message" />
          </PageSpinner>
        </CallbackComponent>
      </div>
    );
  }
}

CallbackPage.propTypes = {
  authCallbackErrorAction: PropTypes.func.isRequired,
  usersPermission: PropTypes.bool.isRequired,
  groupsPermission: PropTypes.bool.isRequired,
  referencePermission: PropTypes.bool.isRequired,
  getResourceTypesAction: PropTypes.func.isRequired,
  setIsAuthenticatedAction: PropTypes.func.isRequired,
  setSelectedResourceTypeAction: PropTypes.func.isRequired,
  setSecondarySelectedResourceTypeAction: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {
    usersPermission: permissions.manageUsers(),
    groupsPermission: permissions.manageGroups(),
    referencePermission: permissions.manageGroupswithReferencePermission(),
  };
}


export default connect(
  mapStateToProps,
  {
    authCallbackErrorAction,
    getResourceTypesAction,
    setIsAuthenticatedAction,
    setSelectedResourceTypeAction,
    setSecondarySelectedResourceTypeAction,
  },
)(CallbackPage);
