import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import AppFrame from 'ui-library/lib/components/panels/AppFrame';
import LeftNavBarActions from 'ui-library/lib/components/panels/left-nav/Actions';

import permissions, { PERMISSIONS } from '../../utils/permissions/permissions';
import { clearSearchResultsAction } from '../../store/actions/search/search';
import * as routes from '../../utils/routes';
import headerLogo from '../../images/HeaderLogo.png';
import {
  PROP_RESOURCE_TYPES,
  RESOURCE_TYPES,
  iconFor,
  getResourcesOfType,
} from '../../utils/resourceTypes';
import { MODEL_TYPES } from '../../utils/modelTypes';
import intlShape from '../../utils/intlPropType';

function renderFooterContent(children) {
  const versionNumber = process.env.REACT_APP_VERSION;
  return (
    <>
      {/* eslint-disable-next-line react/no-array-index-key */}
      {children.map((child, index) => <Fragment key={index}>{child}</Fragment>)}
      <br />Version { versionNumber }
    </>
  );
}

export class NavContainer extends Component {
  constructor(props) {
    super(props);
    const { HEADER_BAR_LOGO } = window;

    this.state = {
      redirectPath: undefined,
      signout: false,
      custom: !!HEADER_BAR_LOGO,
    };

    const leftNavState = this.buildLeftNavState();

    Object.entries(leftNavState).forEach(([key, value]) => {
      if (key === 'tree') {
        props.initLeftNavAction(value);
      } else {
        props.setLeftNavAction(key, value);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    /*
     * NOTE: We are ok to use setState here because of the conditional
     * https://reactjs.org/docs/react-component.html#componentdidupdate
     */
    /* eslint-disable react/no-did-update-set-state */
    const { history, location, leftNavBar } = this.props;
    const { redirectPath, signout } = this.state;

    if (redirectPath && (prevState.redirectPath !== redirectPath)) {
      this.setState({ redirectPath: undefined }, () => history.replace(redirectPath));
    }

    if (prevState.signout !== signout) {
      history.replace(routes.SIGNOUT_ROUTE);
    }

    // Catch any instances where we redirect but don't set the leftnav state to what it should be
    if (location.pathname !== prevProps.location.pathname) {
      if (location.pathname === leftNavBar.selectedNode) return;
      if (Object.values(this.leftNavNodes()).find(node => node.id === location.pathname)) {
        this.handleLeftNavItemChange(location.pathname);
      }
    }
  }

  onMenuValueChange = (id) => {
    this.setState({ signout: id === this.headerBarUserMenuItems()[0].id });
  };

  handleLeftNavItemChange = (itemId) => {
    if (this.props.isFetchingSearch) return;
    // Allow redirect when user form is dirty because the form blocks the transition and handles it
    this.setState({ redirectPath: itemId }, () => {
      if (this.props.blockLeftNavSelection) return;
      this.props.clearSearchResultsAction();
      this.props.selectItemLeftNavAction(itemId);
    });
  };

  handleLeftNavSectionChange = (sectionId) => {
    this.props.selectSectionLeftNavAction(sectionId);
  };

  headerBarUserMenuItems = () => {
    const { intl, oidc } = this.props;
    const PROFILE_SCOPE_ENABLED = _.get(window, 'PD_DADMIN_CONFIG.PROFILE_SCOPE_ENABLED', false);
    const name = _.get(oidc, 'user.profile.name', null);

    if (PROFILE_SCOPE_ENABLED && name) {
      return [
        {
          id: 'signout',
          label: intl.formatMessage({
            id: 'components.nav-header.signout-with-name',
          }, {
            name,
          }),
        },
      ];
    }

    return [
      {
        id: 'signout',
        label: intl.formatMessage({
          id: 'components.nav-header.signout',
        }),
      },
    ];
  };

  headerBarProps = () => {
    const userMenuItems = this.headerBarUserMenuItems();
    const { HEADER_BAR_LOGO } = window;

    const siteLogo = HEADER_BAR_LOGO ? `${process.env.PUBLIC_URL}/${HEADER_BAR_LOGO}` : headerLogo;

    return {
      siteLogo,
      userMenu: userMenuItems,
      onMenuValueChange: this.onMenuValueChange,
      updated: true,
    };
  };

  leftNavNodes = () => {
    const { intl } = this.props;
    // The order of the object is how it will appear in the nav
    return {
      usersManage: {
        id: routes.USERS_SEARCH_ROUTE,
        label: intl.formatMessage({ id: 'components.left-nav.users-section.manage' }),
      },
      usersReport: {
        id: routes.USERS_REPORT,
        label: intl.formatMessage({ id: 'components.left-nav.report' }),
      },
      groupsManage: {
        id: routes.GROUPS_SEARCH_ROUTE,
        label: intl.formatMessage({ id: 'components.left-nav.groups-section.manage' }),
      },
      groupsReport: {
        id: routes.GROUPS_REPORT,
        label: intl.formatMessage({ id: 'components.left-nav.report' }),
      },
    };
  };

  buildLeftNavState = () => {
    const {
      intl,
      usersPermission,
      groupsPermission,
      canCreateGroups,
      canCreateUsers,
    } = this.props;
    const nodes = this.leftNavNodes();
    const openSections = {
      [RESOURCE_TYPES.GROUP]: groupsPermission,
      [RESOURCE_TYPES.USER]: usersPermission,
    };

    const setChildren = (navNodes, options = {}) => {
      const { canCreate, type } = options;
      return Object.keys(navNodes)
        .filter(key => key.includes(type))
        .filter((key) => {
          if (key.includes('Create') && !canCreate) {
            return false;
          }
          return true;
        })
        .map(key => navNodes[key]);
    };

    const usersNavObject = {
      label: intl.formatMessage({ id: 'components.left-nav.users-section.label' }),
      id: RESOURCE_TYPES.USER,
      icon: iconFor(RESOURCE_TYPES.USER),
      children: setChildren(
        nodes,
        { type: RESOURCE_TYPES.USER, canCreate: canCreateUsers },
      ),
    };

    const groupsNavObject = {
      label: intl.formatMessage({ id: 'components.left-nav.groups-section.label' }),
      id: RESOURCE_TYPES.GROUP,
      icon: iconFor(RESOURCE_TYPES.GROUP),
      children: setChildren(
        nodes,
        { type: RESOURCE_TYPES.GROUP, canCreate: canCreateGroups },
      ),
    };

    let navTree = [];

    if (usersPermission) navTree.push(usersNavObject);
    if (groupsPermission) navTree.push(groupsNavObject);

    const genericTree = [];
    const genericResources = getResourcesOfType(RESOURCE_TYPES.GENERIC);

    if (genericResources) {
      genericResources.sort(
        (a, b) => {
          const nameA = _.get(a, 'attributes.displayName');
          const nameB = _.get(b, 'attributes.displayName');
          return nameA < nameB ? -1 : 1;
        },

      ).forEach((resource) => {
        const canReadResource = permissions.hasPermission(PERMISSIONS.READ, resource.id);
        const canCreateResource = permissions.hasPermission(PERMISSIONS.CREATE, resource.id);

        if (canReadResource || canCreateResource) {
          const resourceName = _.get(resource, 'attributes.displayName');
          const genericNodes = {
            genericsManage: {
              id: generatePath(routes.GENERICS_SEARCH_ROUTE, {
                model: MODEL_TYPES.GENERICS,
                type: resource.id,
              }),
              label: intl.formatMessage(
                { id: 'components.left-nav.generic-section.manage' },
                { type: resourceName },
              ),
            },
            genericsReport: {
              id: generatePath(routes.GENERICS_REPORT, {
                model: MODEL_TYPES.GENERICS,
                type: resource.id,
              }),
              label: intl.formatMessage(
                { id: 'components.left-nav.report' },
                { type: resourceName },
              ),
            },
          };

          genericTree.push({
            label: resourceName,
            id: resource.id,
            icon: iconFor(RESOURCE_TYPES.GENERIC),
            children: setChildren(
              genericNodes,
              { type: RESOURCE_TYPES.GENERIC, canCreate: canCreateResource },
            ),
          });

          openSections[resource.id] = true;
        }
      });
    }

    navTree = navTree.concat(genericTree);

    const canManageGroupsAndNotUsers = groupsPermission && !usersPermission;
    return {
      collapsible: true,
      autocollapse: false,
      openSections,
      selectedNode: (
        canManageGroupsAndNotUsers
          ? nodes.groupsManage.path
          : nodes.usersManage.path
      ),
      tree: navTree,
    };
  };

  render() {
    const { leftNavBar } = this.props;
    const { custom } = this.state;
    // Setting the logoSrc as an empty object will render nothing
    const logoSrc = custom ? {} : undefined;
    const footerContent = custom ? undefined : renderFooterContent;
    const leftNavBarProps = { ...leftNavBar, renderFooterContent: footerContent, logoSrc };

    return (
      <AppFrame
        flags={['use-portal']}
        autoSelectItemFromSection
        autoSelectSectionFromItem
        leftNavBarProps={leftNavBarProps}
        navTree={leftNavBar.tree}
        onItemChange={this.handleLeftNavItemChange}
        onSectionChange={this.handleLeftNavSectionChange}
        headerBarProps={this.headerBarProps()}
      />
    );
  }
}

NavContainer.propTypes = {
  intl: intlShape.isRequired,
  clearSearchResultsAction: PropTypes.func.isRequired,
  setLeftNavAction: PropTypes.func.isRequired,
  initLeftNavAction: PropTypes.func.isRequired,
  selectItemLeftNavAction: PropTypes.func.isRequired,
  usersPermission: PropTypes.bool.isRequired,
  groupsPermission: PropTypes.bool.isRequired,
  isFetchingSearch: PropTypes.bool.isRequired,
  leftNavBar: PropTypes.shape({
    selectedNode: PropTypes.string,
    tree: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  blockLeftNavSelection: PropTypes.bool.isRequired,
  canCreateGroups: PropTypes.bool.isRequired,
  canCreateUsers: PropTypes.bool.isRequired,
  selected: PropTypes.shape({
    attributes: PropTypes.shape({
      resourceType: PropTypes.oneOf(PROP_RESOURCE_TYPES),
    }).isRequired,
  }).isRequired,
  selectSectionLeftNavAction: PropTypes.func.isRequired,
  oidc: PropTypes.shape({
    user: PropTypes.shape({
      profile: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    groupsPermission: permissions.manageGroups(),
    canCreateGroups: permissions.hasPermissionForType(PERMISSIONS.CREATE, RESOURCE_TYPES.GROUP),
    usersPermission: permissions.manageUsers(),
    canCreateUsers: permissions.hasPermissionForType(PERMISSIONS.CREATE, RESOURCE_TYPES.USER),
    isFetchingSearch: state.search.isFetching,
    leftNavBar: state.leftNavBar,
    blockLeftNavSelection: state.modelForm.dirty,
    selected: state.resourceTypes.selected,
    oidc: state.oidc,
  };
}

const connectedComponent = connect(
  mapStateToProps,
  {
    clearSearchResultsAction,
    setLeftNavAction: LeftNavBarActions.set,
    initLeftNavAction: LeftNavBarActions.init,
    selectItemLeftNavAction: LeftNavBarActions.selectItem,
    selectSectionLeftNavAction: LeftNavBarActions.toggleSection,
  },
)(NavContainer);

export default injectIntl(withRouter(connectedComponent));
