import { SESSION_TERMINATED, USER_EXPIRED } from 'redux-oidc';
import { AUTHENTICATION, UNAUTHENTICATE_USER } from '../../actions/actionTypes';
import { SUCCESS, FAILURE } from '../../actions';

const initialState = {
  isAuthenticated: false,
  error: undefined,
};

function reduceAuthenticationSuccess(prevState) {
  return {
    ...prevState,
    isAuthenticated: true,
    error: undefined,
  };
}

function reduceAuthenticationFailure(error, prevState) {
  return {
    ...prevState,
    isAuthenticated: false,
    error,
  };
}

export default function (state = initialState, action) {
  switch (action.type) {
    case UNAUTHENTICATE_USER:
    case SESSION_TERMINATED:
    case USER_EXPIRED:
      return {
        ...state,
        ...initialState,
      };
    case AUTHENTICATION[SUCCESS]:
      return reduceAuthenticationSuccess(state);
    case AUTHENTICATION[FAILURE]:
      return reduceAuthenticationFailure(action.error, state);
    default:
      return state;
  }
}

