// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';

// UI-Library
import Spinner from 'ui-library/lib/components/general/Spinner';
import InlineMessage from 'ui-library/lib/components/general/InlineMessage';

// Components
import ModalWithButton from '../../shared/modals/ModalWithButton/ModalWithButton';
import CreateModal from '../../shared/modals/CreateModal/CreateModal';
import Page from '../../shared/layout/Page/Page';
import SearchBar from '../SearchBar/SearchBar';
import SearchResults from '../SearchResults/SearchResults';
import SortDropdown from '../SortDropdown/SortDropdown';

// Store
import {
  getSearchResults,
  clearSearchResultsAction,
  sortSearchResultsAction,
} from '../../../store/actions/search/search';
import {
  filterResourceTypesAction,
  setSelectedResourceTypeAction,
} from '../../../store/actions/resourceTypes/resourceTypes';
import { createUserAction } from '../../../store/actions/userActions';
import { createGroupAction } from '../../../store/actions/groups/groups';
import { createGenericAction } from '../../../store/actions/generics';

// Utils
import permissions, { PERMISSIONS } from '../../../utils/permissions/permissions';
import { MODEL_TYPES, PROP_MODEL_TYPES } from '../../../utils/modelTypes';
import { NOT_FOUND_ROUTE } from '../../../utils/routes';
import { RESOURCE_TYPES, getResourceById } from '../../../utils/resourceTypes';
import { getTitleOrPrimaryAttributeKey } from '../../../utils/helpers';
import intlShape from '../../../utils/intlPropType';

// Styling
import './SearchPage.css';

function searchAttributeNamesToString(meta) {
  return meta && Object.values(meta.attributes)
    .filter(attr => attr.searchAttribute)
    .map(attr => attr.displayName)
    .join(', ');
}

export class SearchPage extends Component {
  constructor(props) {
    super(props);

    if (props.match.params.model === MODEL_TYPES.GROUPS &&
      !props.groupsPermission &&
      !props.referencePermission) {
      props.history.replace(NOT_FOUND_ROUTE);
    }

    this.filterResourceTypes = this.filterResourceTypes.bind(this);
    this.checkReferencePermission = this.checkReferencePermission.bind(this);

    this.state = {
      newItem: undefined,
      recentDelete: false,
      selectedOption: undefined,
      referencePermission: false,
    };
  }

  componentDidMount() {
    this.filterResourceTypes();
    this.checkReferencePermission();
  }

  async componentDidUpdate(prevProps) {
    const {
      groupsPermission,
      referencePermission,
      history,
      match,
      search,
    } = this.props;
    const { recentDelete } = this.state;

    if (prevProps.search.isFetching !== search.isFetching) {
      if (recentDelete) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ recentDelete: false });
      }
    }

    if (match.params.model === MODEL_TYPES.GROUPS && !groupsPermission && !referencePermission) {
      history.replace(NOT_FOUND_ROUTE);
    } else if (
      (prevProps.match.params.type !== match.params.type ||
        prevProps.match.params.model !== match.params.model)
      && !search.queryString
    ) {
      this.filterResourceTypes();
    }
  }

  async filterResourceTypes() {
    let { selected } = this.props.resourceTypes;
    const { model, type } = this.props.match.params;
    let filterType = RESOURCE_TYPES.USER;

    if (model === MODEL_TYPES.GROUPS) {
      filterType = RESOURCE_TYPES.GROUP;
    } else if (model === MODEL_TYPES.GENERICS) {
      filterType = RESOURCE_TYPES.GENERIC;
      selected = getResourceById(type);
      await this.props.setSelectedResourceTypeAction(selected);
    }
    this.checkReferencePermission();

    const filter = {
      byType: [filterType],
      byPermission: PERMISSIONS.READ,
    };

    this.props.filterResourceTypesAction(filter, {
      getParent: false,
      setCreate: true,
    });
  }

  searchQuery = async (queryString = '', options = {}) => {
    const { skipMessage } = options;
    this.setState({ selectedOption: undefined });

    try {
      await this.props.getSearchResults(queryString, { skipMessage });
    } catch (error) {
      // Errors handled by action
    }
  };

  clearSearch = () => {
    this.setState({ newItem: undefined });
    this.props.clearSearchResultsAction();
  };

  displaySort = () => {
    const { intl } = this.props;
    const {
      isFetching,
      meta,
      primarySearchKey,
      results,
    } = this.props.search;

    if (results.length && !isFetching) {
      const displayName = (
        meta
        && meta.attributes[primarySearchKey]
        && meta.attributes[primarySearchKey].displayName
      );

      const options = [
        {
          label: intl.formatMessage({
            id: 'components.sort-dropdown.options.attr-asc',
          }, {
            displayName,
          }),
          value: 0,
        }, {
          label: intl.formatMessage({
            id: 'components.sort-dropdown.options.attr-desc',
          }, {
            displayName,
          }),
          value: 1,
        },
      ];

      return (
        <>
          <div className="search-controls__filters">
            <FormattedMessage
              id="page.search.results"
              values={{
                resultsCount: results.length,
              }}
            />
            <SortDropdown
              className="sort-dropdown"
              label={intl.formatMessage({ id: 'components.sort-dropdown.label' })}
              onSortClick={this.handleSortClick}
              options={options}
              selectedOption={this.state.selectedOption}
            />
          </div>
        </>
      );
    }

    return null;
  };

  handleSortClick = (selectedMenuItem) => {
    const { primarySearchKey } = this.props.search;

    const sortBy = {
      0: { key: primarySearchKey, order: 'asc' },
      1: { key: primarySearchKey, order: 'desc' },
    };
    this.setState({ selectedOption: selectedMenuItem });

    this.props.sortSearchResultsAction(sortBy[selectedMenuItem.value]);
  };

  handleSave = async (newItem) => {
    const { resourceTypes } = this.props;
    const { attributes } = resourceTypes.selected;
    const primaryKey = getTitleOrPrimaryAttributeKey(attributes);
    const queryString = newItem.attributes[primaryKey][0];

    this.setState({ newItem });
    await this.props.setSelectedResourceTypeAction(resourceTypes.create.selected);
    this.searchQuery(queryString, { skipMessage: true });
  };

  handleDelete = () => {
    this.setState({ recentDelete: true });
  };

  checkReferencePermission = () => {
    const { resourceTypes } = this.props;
    const resourceTypesData = resourceTypes.data;
    const filteredRTData = resourceTypesData.filter(item => item.id === 'groups');
    if (filteredRTData.length) {
      if (Object.prototype.hasOwnProperty.call(filteredRTData[0].attributes, 'permissions')) {
        const filteredGroupPermission = filteredRTData[0].attributes.permissions;
        const referenceGroupPermission = filteredGroupPermission.filter(permission => permission === 'reference');
        if (referenceGroupPermission) {
          this.setState({ referencePermission: true });
        }
      }
    }
  };

  render() {
    const {
      newItem,
      recentDelete,
    } = this.state;
    const {
      isFetching,
      meta,
      primarySearchKey,
      queryString,
      results,
    } = this.props.search;
    const {
      intl,
      match,
      messages,
      resourceTypes,
    } = this.props;

    const { model, type } = match.params;
    const selected = getResourceById(type);
    const { displayName, resourceType } = resourceTypes.selected.attributes;
    const messageType = !_.isEmpty(messages) && messages[0].type;
    const canCreate = permissions.hasPermission(PERMISSIONS.CREATE, resourceTypes.selected.id);
    const addButtonLabel = (resourceType !== RESOURCE_TYPES.GENERIC)
      ? displayName : selected?.attributes.displayName;
    const createAction = {
      [RESOURCE_TYPES.USER]: this.props.createUserAction,
      [RESOURCE_TYPES.GROUP]: this.props.createGroupAction,
      [RESOURCE_TYPES.GENERIC]: this.props.createGenericAction,
    };
    const loading = isFetching && !recentDelete;
    const placeholderAttributes = searchAttributeNamesToString(resourceTypes.selected.attributes);
    const searchBarPlaceholder =
      intl.formatMessage({
        id: 'components.search-bar.placeholder',
      }, {
        type: _.get(selected, 'attributes.displayName') || _.capitalize(model),
        attributes: placeholderAttributes,
      });
    const pageTitle =
      intl.formatMessage({
        id: 'page.search.title',
      }, {
        type: _.get(selected, 'attributes.displayName') || _.capitalize(model),
      });

    const displayResults = (
      <>
        {
          (queryString === undefined)
            ?
            (
              <div className="search__no-results">
                <InlineMessage
                  bordered={false}
                  type={InlineMessage.MessageTypes.NOTICE}
                >
                  <FormattedMessage id="components.dual-column-search.shared.nothing-to-display" />
                </InlineMessage>
              </div>
            )
            :
            (
              <SearchResults
                handleDelete={this.handleDelete}
                messageType={messageType}
                meta={meta}
                modelType={model}
                newItemId={newItem && newItem.id}
                onEnableToggle={model === (MODEL_TYPES.USERS) && this.enableOrDisableUser}
                onResetUserPasswordClick={model === (MODEL_TYPES.USERS) && this.resetUserPassword}
                primarySearchAttribute={primarySearchKey}
                queryString={queryString}
                results={results}
                referencePermission={this.state.referencePermission}
              />
            )
        }
      </>
    );

    return (
      <Page className="SearchPage" title={pageTitle}>
        <div className="search-page-header">
          <div className="search-controls">
            <SearchBar
              showDropDown={model !== MODEL_TYPES.GENERICS}
              className="search-controls__item"
              onSearch={this.searchQuery}
              onClearSearch={this.clearSearch}
              placeholder={searchBarPlaceholder}
              queryString={queryString}
              isDisabled={loading}
              pageModel={model}
              persistQueryString
            />
            {this.displaySort()}
          </div>
          <ModalWithButton
            buttonProps={{
              iconName: 'add',
              label: {
                id: 'components.modal.button.label',
                label: `New ${addButtonLabel}`,
              },
            }}
            modalTitle={{
              id: 'components.modal.title',
              title: `New ${addButtonLabel}`,
            }}
            showButton={canCreate}
            isSearchPage
            filterResourceTypes={this.filterResourceTypes}
          >
            <CreateModal
              createAction={createAction[resourceType]}
              handleSave={this.handleSave}
              match={match}
            />
          </ModalWithButton>
        </div>
        <Spinner show={loading}>
          {displayResults}
        </Spinner>
      </Page>
    );
  }
}

SearchPage.propTypes = {
  createUserAction: PropTypes.func.isRequired,
  createGroupAction: PropTypes.func.isRequired,
  createGenericAction: PropTypes.func.isRequired,
  clearSearchResultsAction: PropTypes.func.isRequired,
  filterResourceTypesAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  groupsPermission: PropTypes.bool.isRequired,
  referencePermission: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      model: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
    url: PropTypes.string,
  }).isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()),
  resourceTypes: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    selected: PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({
        attributes: PropTypes.shape({}).isRequired,
        displayName: PropTypes.string,
        resourceType: PropTypes.string,
      }).isRequired,
    }),
    create: PropTypes.shape({
      selected: PropTypes.shape({
        id: PropTypes.string,
        attributes: PropTypes.shape({
          attributes: PropTypes.shape({}).isRequired,
          displayName: PropTypes.string,
          resourceType: PropTypes.string,
        }).isRequired,
      }),
    }),
  }),
  search: PropTypes.shape({
    dataType: PropTypes.oneOf(PROP_MODEL_TYPES),
    isFetching: PropTypes.bool,
    queryString: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({}).isRequired,
    }).isRequired),
    meta: PropTypes.shape({
      attributes: PropTypes.shape({}).isRequired,
    }),
    primarySearchKey: PropTypes.string,
  }),
  setSelectedResourceTypeAction: PropTypes.func.isRequired,
  sortSearchResultsAction: PropTypes.func.isRequired,
};

SearchPage.defaultProps = {
  messages: [],
  resourceTypes: {
    selected: undefined,
    create: {
      selected: undefined,
    },
  },
  search: {
    dataType: undefined,
    isFetching: false,
    meta: undefined,
    primarySearchKey: undefined,
    queryString: undefined,
    results: [],
  },
};

function mapStateToProps(state) {
  const {
    dataType,
    isFetching,
    meta,
    queryString,
    results,
  } = state.search;

  return {
    groupsPermission: permissions.manageGroups(),
    referencePermission: permissions.manageGroupswithReferencePermission(),
    messages: state.messageContainer.messages,
    resourceTypes: state.resourceTypes,
    search: {
      dataType,
      isFetching,
      meta,
      primarySearchKey: getTitleOrPrimaryAttributeKey(meta),
      queryString,
      results,
    },
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    createUserAction,
    createGroupAction,
    createGenericAction,
    clearSearchResultsAction,
    filterResourceTypesAction,
    getSearchResults,
    setSelectedResourceTypeAction,
    sortSearchResultsAction,
  },
)(SearchPage));
