import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
  Formik,
  Form,
  FieldArray,
} from 'formik';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ButtonBar from 'ui-library/lib/components/forms/ButtonBar';
import { selectItem } from 'ui-library/lib/components/panels/left-nav/Actions';

import './ModelFormV2.css';
import AlertModal from '../../modals/AlertModal/AlertModal';
import FormFields from '../FormFields/FormFields';
import CategorizedFormFields from '../CategorizedFormFields/CategorizedFormFields';
import {
  generateModelFormStatus,
  generateModelFormValues,
  submitModelForm,
  validateSchema,
} from '../../../../utils/formHelpers';
import {
  categorizeAttributes,
  convertStringToArray,
  getNonBlacklistedAttributes,
  isUserPasswordCategorized,
} from '../../../../utils/helpers';
import { setModelFormAction } from '../../../../store/actions/modelForm/modelFormActions';
import { setModalAction } from '../../../../store/actions/modal/modal';

const ModelFormV2 = (props) => {
  const {
    areFieldsValid,
    hasAlertSave,
    isModalOpen,
    meta,
    model,
    onReset,
    selected,
    resourceTypes,
  } = props;
  const { readOnlyAttributes } = model.meta;
  const { attributeErrors } = useSelector(state => state.modelForm);
  const history = useHistory();
  const intl = useIntl();
  const formikRef = useRef();
  const [nextLocation, setNextLocation] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [alertModalType, setAlertModalType] = useState('alert');
  const dispatch = useDispatch();
  const categories = meta.attributeCategories;

  /*
   * Set field back to untouched when it has a server error so we can display a server error only
   * when it hasn't been touched
   */
  useEffect(() => {
    Object.keys(attributeErrors).forEach(key => formikRef.current.setFieldTouched(key, false));
  }, [attributeErrors]);

  // Clear any server-generated attribute errors on unmounting
  useEffect(() => () => dispatch(setModelFormAction({ attributeErrors: {} })), [dispatch]);

  /*
   * Using formikRef to use formik props outside of the formik render
   * https://stackoverflow.com/questions/60358836/how-to-formik-setfieldvalue-in-useeffect-hook
   */

  const UseUnblock = (dirty, valid) => {
    useEffect(() => {
      if (isModalOpen) {
        dispatch(setModalAction({ dirty, valid }));
      } else {
        dispatch(setModelFormAction({ dirty }));
      }
      // Prevent and capture URL transition when form is dirty
      const unblock = history.block((next) => {
        if (dirty) {
          setModalOpen(true);
          setNextLocation(next);
        }

        return !dirty;
      });
      return () => unblock();
    }, [dirty, valid]);
  };

  const handleModalSave = () => {
    if (!modalOpen) {
      setModalOpen(true);
      setAlertModalType('warning');
    } else {
      setModalOpen(false);
      formikRef.current.submitForm();
    }
  };

  useEffect(() => {
    setResetForm(false);
  }, [resetForm]);

  useEffect(() => {
    if (hasAlertSave) {
      handleModalSave();
    }
    /*
     *We need this command because otherwise the linter will automically add in
     *handleModalSave into the useEffect parameter
     */
  }, [hasAlertSave]);

  const handleModalCancel = () => {
    setModalOpen(false);
    setNextLocation(undefined);
  };


  const handleModalReset = async () => {
    await formikRef.current.resetForm();
    // Update URL and select the left nav item associated with the next location change
    if (nextLocation && nextLocation.pathname) {
      history.replace(nextLocation.pathname);
      selectItem(nextLocation.pathname);
    }
  };

  return (
    <Formik
      enableReinitialize
      innerRef={formikRef}
      initialStatus={generateModelFormStatus(props)}
      initialValues={generateModelFormValues(props)}
      onSubmit={values => submitModelForm(values, props)}
      onReset={() => setResetForm(true)}
      validationSchema={validateSchema(props)}
    >
      {({
        dirty,
        errors,
        handleBlur,
        handleChange,
        handleReset,
        isSubmitting,
        isValid,
        setFieldTouched,
        setFieldValue,
        setStatus,
        status,
        submitForm,
        touched,
        values,
      }) => {
        /*
         * Check that all custom fields have a value of true to indicate they are valid
         */
        const allCustomFieldsValid = Object.values(status.customValidation).every(item => item);
        const isFullyValid = isValid && areFieldsValid && allCustomFieldsValid;
        const attributes = getNonBlacklistedAttributes(meta.attributes, values);
        const categorizedAttrs = categorizeAttributes(categories, attributes, intl);
        UseUnblock(dirty, isFullyValid);

        const createFormFields = (attr, key) => (
          <FieldArray
            key={key}
            name={attr}
            render={arrayHelpers => (
              <FormFields
                arrayHelpers={arrayHelpers}
                attr={attr}
                errors={touched[attr] ? convertStringToArray(errors[attr]) : {}}
                formValues={values}
                handleBlur={handleBlur}
                handleChange={handleChange}
                meta={meta}
                readOnlyAttributes={readOnlyAttributes}
                resetForm={resetForm}
                selected={selected}
                resourceTypes={resourceTypes}
                serverErrors={attributeErrors}
                setFieldTouched={setFieldTouched}
                setFieldValue={setFieldValue}
                setStatus={setStatus}
                status={status}
                touched={touched}
                values={values[attr]}
                model={model}
              />
            )}
          />
        );

        return (
          <>
            <AlertModal
              cancelText={intl.formatMessage({ id: 'common.discard-changes' })}
              closeText={intl.formatMessage({ id: 'common.cancel' })}
              isOpen={modalOpen}
              modalDescription={intl.formatMessage({ id: `containers.model-form.${alertModalType}-modal.message` })}
              modalTitle={intl.formatMessage({ id: 'containers.model-form.alert-modal.title' })}
              onCancel={handleModalReset}
              onClose={handleModalCancel}
              onSave={handleModalSave}
              preventSave={!isFullyValid}
              saveText={intl.formatMessage({ id: 'common.save' })}
            />
            <Form className="ModelForm">
              {
              (categorizedAttrs.length > 0) ?
                <CategorizedFormFields
                  categorizedAttrs={categorizedAttrs}
                  createFormFields={createFormFields}
                  hasUserPassword={'userPassword' in values}
                  isUserPasswordCategorized={isUserPasswordCategorized(attributes)}
                />
              :
                Object.keys(values).map((attr, index) => {
                  const key = index;
                  return createFormFields(attr, key);
                })
            }
              <ButtonBar
                cancelText={intl.formatMessage({ id: 'common.reset' })}
                enableSavingAnimation={isSubmitting}
                flags={['use-portal']}
                onCancel={() => {
                  handleReset();
                  onReset();
                }}
                onSave={() => {
                  // Get all required field keys as string[]
                  const requiredFields =
                    Object
                      .entries(meta.attributes)
                      .filter(([, value]) => value.required)
                      .filter(requiredValue => requiredValue[1].dataType === 'string')
                      .map(([key]) => key);

                  // True if any required fields have length of 0
                  const fieldErrors =
                    requiredFields
                      .filter(fieldName => values[fieldName] && !values[fieldName][0]
                        .trim()
                        .length)
                      .length;
                  // If any fields are blank, show warning modal
                  // eslint-disable-next-line no-unused-expressions
                  fieldErrors ? handleModalSave(values) : submitForm();
                }}
                saveDisabled={!isFullyValid}
                saveText={intl.formatMessage({ id: 'common.save' })}
                visible={Object.keys(touched).length > 0 && dirty}
              />
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

ModelFormV2.propTypes = {
  areFieldsValid: PropTypes.bool,
  hasAlertSave: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  meta: PropTypes.shape({
    attributeCategories: PropTypes.arrayOf(PropTypes.string),
    attributes: PropTypes.shape({}),
  }),
  model: PropTypes.shape({
    attributes: PropTypes.shape({}),
    id: PropTypes.string,
    included: PropTypes.arrayOf(PropTypes.shape()),
    meta: PropTypes.shape({
      readOnlyAttributes: PropTypes.arrayOf(PropTypes.string),
    }),
    type: PropTypes.string,
  }),
  onReset: PropTypes.func,
  // onSave needed in formikProps
  // eslint-disable-next-line react/no-unused-prop-types
  onSave: PropTypes.func.isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

ModelFormV2.defaultProps = {
  areFieldsValid: true,
  hasAlertSave: false,
  isModalOpen: false,
  meta: {
    attributes: {},
  },
  model: {
    attributes: {},
    id: '',
    included: [],
    meta: {
      readOnlyAttributes: [],
    },
    type: '',
  },
  onReset: () => {},
};

export default ModelFormV2;
