// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

// UI-Library
import InputRow from 'ui-library/lib/components/layout/InputRow';
import EllipsisLoader from 'ui-library/lib/components/general/EllipsisLoader';
import Button from 'ui-library/lib/components/buttons/Button';

// Components
import Page from '../../layout/Page/Page';
import UploadModal from '../../modals/UploadModal/UploadModal';
import ResourceTypeContainer from '../../../../containers/ResourceTypeContainer/ResourceTypeContainer';
import DropDownList from '../../list-inputs/DropDownList/DropDownList';
import ReportFilters from '../../../report/ReportFilters/ReportFilters';
import ReportPreviewTable from '../../../report/ReportTable/ReportTable';
import ModalWithButton from '../../modals/ModalWithButton/ModalWithButton';

// Store
import {
  filterResourceTypesAction,
  setSelectedResourceTypeAction,
} from '../../../../store/actions/resourceTypes/resourceTypes';
import {
  generateReportAction,
  getReportResultsAction,
} from '../../../../store/actions/report/report';
import { getSearchResults } from '../../../../store/actions/search/search';

// Utils
import intlShape from '../../../../utils/intlPropType';
import { createDropDownOptions } from '../../../../utils/helpers';
import permissions, { PERMISSIONS } from '../../../../utils/permissions/permissions';
import { MODEL_TYPES } from '../../../../utils/modelTypes';
import { getResourceById, RESOURCE_TYPES } from '../../../../utils/resourceTypes';

// Styling
import './ReportPage.css';

export class ReportPage extends Component {
  state = {
    fields: undefined,
    selectedOptions: {},
    successfulUpload: false,
  };

  componentDidMount() {
    this.filterResourceTypes();
    this.getReportResults();
    this.createDropDowns([MODEL_TYPES.GROUPS, 'memberTypes']);
  }

  async componentDidUpdate(prevProps) {
    const {
      match: { url },
      modal,
      resourceTypes: { selected },
      search,
    } = this.props;

    if (prevProps.match.url !== url) {
      this.filterResourceTypes();
      this.getReportResults();
    }

    if (!_.isEqual(prevProps.resourceTypes.selected, selected)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedOptions: {},
      });
    }

    if (!modal.open) {
      if (!_.isEqual(prevProps.search, search)) {
        await this.setDropDownOptions(search, MODEL_TYPES.GROUPS);
        this.setSecondaryResourceDropDownOptions();
      }
    }
  }

  getReportResults = () => {
    const { model } = this.props.match.params;
    if (model === MODEL_TYPES.GROUPS) {
      this.getGroupsSearch();
    } else {
      this.props.getReportResultsAction();
      this.setState({ successfulUpload: false });
    }
  };

  getGroupsSearch = async () => {
    await this.props.getSearchResults('', {
      skipMessage: true,
      includeGroupMeta: true,
    });
    this.getGroupsReportResults();
  };

  getGroupsReportResults = () => {
    const { fields } = this.state;
    const selectedOptions = Object
      .entries(fields)
      .reduce((result, [key, val]) => {
        const filter = {
          groups: 'groupId',
          memberTypes: 'resourceId',
        };

        if (val.selectedOption && filter[key]) {
          return { ...result, [filter[key]]: val.selectedOption.id };
        }

        return { ...result };
      }, {});

    this.props.getReportResultsAction({ selectedOptions });
    this.setState({
      selectedOptions,
      successfulUpload: false,
    });
  };

  setDropDownOptions = (props, type) => {
    const { successfulUpload } = this.state;
    const { meta, results } = props;
    const groupSelected = _.get(this.props.resourceTypes, 'create.groupSelected', undefined);
    const data = props.data || results;

    if (!data || data.length === 0 || !meta) {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            options: undefined,
            selectedOption: undefined,
          },
        },
      }));
      return;
    }

    const options = createDropDownOptions(data, meta);
    const selectedOption = successfulUpload
      ? options.find(option => option.id === groupSelected.id)
      : options[0];

    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          options,
          selectedOption,
        },
      },
    }));
  };

  setSecondaryResourceDropDownOptions = () => {
    const { data } = this.props.resourceTypes;
    const { results } = this.props.search;
    const {
      fields: { groups },
      successfulUpload,
    } = this.state;
    const result = results.find(item => item.id === groups.selectedOption.id);
    const allMemberTypes = _.get(result, 'meta.allMemberTypes', []);
    const secondarySelected = _.get(this.props.resourceTypes, 'create.secondarySelected', undefined);

    const filter = data
      .filter((item) => {
        const { resourceType } = item.attributes;
        return allMemberTypes.includes(item.id) && resourceType !== RESOURCE_TYPES.GROUP;
      });
    const options = filter
      .map(item => ({
        id: item.id,
        label: item.attributes.displayName,
        type: item.attributes.resourceType,
        value: item.attributes.resourceEndpoint,
      }));
    const selectedOption = successfulUpload
      ? options.find(option => option.id === secondarySelected.id)
      : options[0];

    this.setState(state => ({
      fields: {
        ...state.fields,
        memberTypes: {
          ...state.fields.memberTypes,
          options,
          selectedOption,
        },
      },
    }));
  };

  createDropDowns = (types) => {
    types.forEach((type) => {
      this.setState(state => ({
        fields: {
          ...state.fields,
          [type]: {
            errorMessage: 'validation.required',
            label: `containers.model-form.${type}-dropdown-label`,
            labelPrompt: 'components.search-bar-drop-down.list-prompt',
            options: undefined,
            required: true,
            selectedOption: undefined,
            showError: false,
            type,
          },
        },
      }));
    });
  };

  async filterResourceTypes() {
    let { selected } = this.props.resourceTypes;
    const { model, type } = this.props.match.params;
    let filterType = RESOURCE_TYPES.USER;

    if (model === MODEL_TYPES.GROUPS) {
      filterType = RESOURCE_TYPES.GROUP;
    } else if (model === MODEL_TYPES.GENERICS) {
      filterType = RESOURCE_TYPES.GENERIC;
      selected = getResourceById(type);
      await this.props.setSelectedResourceTypeAction(selected);
    }

    const filter = {
      byType: [filterType],
      byPermission: PERMISSIONS.READ,
    };

    this.props.filterResourceTypesAction(filter, {
      getParent: false,
      setCreate: true,
    });
  }

  handleResourceTypeChange = () => {
    this.getReportResults();
  };

  handleDropDownChange = (selectedOption, type) => {
    this.setState(state => ({
      fields: {
        ...state.fields,
        [type]: {
          ...state.fields[type],
          selectedOption,
        },
      },
    }), () => this.getGroupsReportResults());
  };

  handleDropDownToggle = (type) => {
    this.setState((state) => {
      const {
        required,
        selectedOption,
      } = state.fields[type];
      return ({
        fields: {
          ...state.fields,
          [type]: {
            ...state.fields[type],
            showError: required && !selectedOption,
          },
        },
      });
    });
  };

  handleSave = async () => {
    const { resourceTypes } = this.props;
    this.setState({ successfulUpload: true });
    await this.props.setSelectedResourceTypeAction(resourceTypes.create.selected);
    this.getReportResults();
  };

  render() {
    const {
      intl,
      match,
      report,
      resourceTypes,
      search,
    } = this.props;
    const { model } = this.props.match.params;
    const {
      fields,
      selectedOptions,
    } = this.state;
    const reportError = !_.isUndefined(report.error);
    const canDownload = permissions.hasPermission(PERMISSIONS.DOWNLOAD, resourceTypes.selected.id);
    const canUpload = permissions.hasPermission(PERMISSIONS.UPLOAD, resourceTypes.selected.id);

    return (
      <Page
        title="Reporting"
        className="report-page"
      >
        <div className="report__actions">
          <ResourceTypeContainer
            onFilter={this.handleResourceTypeChange}
            primary
            show
            showLabel
            isReportPage
          />
          {
            (search.isFetching) ?
              <EllipsisLoader data-id="demo-ellipsis-loader" loading /> :
            !_.isEmpty(fields) &&
            Object.keys(fields).map((key) => {
              const {
                errorMessage,
                label,
                labelPrompt,
                options,
                required,
                selectedOption,
                showError,
                type,
                } = fields[key];

                if (options && options.length > 0) {
                return (
                  <InputRow key={key}>
                    <DropDownList
                      errorMessage={(showError) ? errorMessage : ''}
                      handleOnToggle={() => this.handleDropDownToggle(type)}
                      label={{
                        id: label,
                      }}
                      labelPrompt={labelPrompt}
                      handleOnChange={option => this.handleDropDownChange(option, type)}
                      options={options}
                      required={required}
                      selectedOption={selectedOption}
                    />
                  </InputRow>
                );
                }
                return null;
            })
          }
          <div className="report__actions-buttons">
            {
              canDownload &&
                <Button
                  disabled={report.isFetching || reportError || report.download.isFetching}
                  inline
                  iconName="download"
                  label={intl.formatMessage({ id: 'report.download.label' })}
                  loading={report.download.isFetching}
                  onClick={() => this.props.generateReportAction()}
                />
            }
            <ModalWithButton
              buttonProps={{
                disabled: (report.isFetching || reportError || report.download.isFetching),
                inline: true,
                iconName: 'file',
                label: {
                  id: 'components.modal.button.label',
                  label: 'Upload File',
                },
              }}
              modalTitle={{
                id: 'components.modal.title',
                title: 'Upload File',
              }}
              maximize
              showButton={canUpload}
            >
              <UploadModal
                isFetching={report.upload.isFetching}
                handleSave={this.handleSave}
                match={match}
              />
            </ModalWithButton>
          </div>
        </div>
        <ReportFilters
          report={report}
          selectedOptions={selectedOptions}
          selectedResourceTypeId={resourceTypes.selected.id}
        />
        <div className="report">
          <ReportPreviewTable
            error={report.error || search.error}
            model={model}
            report={report}
            selectedOptions={selectedOptions}
            selectedResourceTypeId={resourceTypes.selected.id}
          />
        </div>
      </Page>
    );
  }
}

ReportPage.propTypes = {
  filterResourceTypesAction: PropTypes.func.isRequired,
  generateReportAction: PropTypes.func.isRequired,
  getReportResultsAction: PropTypes.func.isRequired,
  getSearchResults: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      model: PropTypes.string.isRequired,
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
  modal: PropTypes.shape({
    open: PropTypes.bool,
  }).isRequired,
  report: PropTypes.shape({
    download: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
    error: PropTypes.string,
    filters: PropTypes.shape({
      attributes: PropTypes.shape({}),
      selectedFieldsAttrs: PropTypes.arrayOf(PropTypes.string),
      filterTypeAttrs: PropTypes.shape({}),
    }),
    isFetching: PropTypes.bool,
    results: PropTypes.shape({
      header: PropTypes.arrayOf(PropTypes.string),
      rows: PropTypes.instanceOf(Array),
    }),
    upload: PropTypes.shape({
      isFetching: PropTypes.bool,
    }),
  }),
  resourceTypes: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    selected: PropTypes.shape({
      id: PropTypes.string,
    }),
    create: PropTypes.shape({
      selected: PropTypes.shape({}),
    }),

  }),
  search: PropTypes.shape({
    error: PropTypes.string,
    isFetching: PropTypes.bool,
    meta: PropTypes.shape({}),
    results: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      attributes: PropTypes.shape({}).isRequired,
    }).isRequired),
  }),
  setSelectedResourceTypeAction: PropTypes.func.isRequired,
};

ReportPage.defaultProps = {
  report: {
    download: {
      isFetching: false,
    },
    error: undefined,
    isFetching: false,
    results: {},
    upload: {
      isFetching: false,
    },
  },
  resourceTypes: {
    data: [],
    selected: undefined,
    create: {
      selected: undefined,
    },
  },
  search: {
    error: undefined,
    isFetching: false,
    meta: {},
    results: [],
  },
};

function mapStateToProps(state) {
  const { modal, report, search } = state;
  return {
    messages: state.messageContainer.messages,
    modal,
    report: {
      download: report.download,
      error: report.error,
      filters: report.filters,
      isFetching: report.isFetching,
      results: report.results,
      upload: report.upload,
    },
    resourceTypes: state.resourceTypes,
    search: {
      error: search.error,
      isFetching: search.isFetching,
      meta: search.meta,
      results: search.results,
    },
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    filterResourceTypesAction,
    generateReportAction,
    getReportResultsAction,
    getSearchResults,
    setSelectedResourceTypeAction,
  },
)(ReportPage));
