import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormSearchBox from 'ui-library/lib/components/forms/FormSearchBox';

import ResourceTypeContainer from '../../../containers/ResourceTypeContainer/ResourceTypeContainer';

import './SearchBar.css';

class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.searchbox = React.createRef();

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearchClear = this.handleSearchClear.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleFilterSearch = this.handleFilterSearch.bind(this);

    this.state = {
      queryString: '',
      searchPressed: false,
    };
  }

  componentDidUpdate(prevProps) {
    /*
     * NOTE: We are ok to use setState here because of the conditional
     * https://reactjs.org/docs/react-component.html#componentdidupdate
     */
    /* eslint-disable react/no-did-update-set-state */
    const { persistQueryString, queryString } = this.props;
    if (persistQueryString && prevProps.queryString !== queryString) {
      this.setState({
        queryString,
      });
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Enter' && !this.props.isDisabled) {
      this.props.onSearch(this.state.queryString);
      this.setState({ searchPressed: true });
    }
  }

  handleSearchChange(queryString) {
    if (!this.props.isDisabled) {
      this.setState({ queryString });
    }
  }

  handleSearchClear() {
    this.setState({ queryString: '' });
  }

  handleFilterSearch() {
    this.handleSearchClear();
    this.searchbox.current.searchBoxFocus();
    this.setState({ searchPressed: false });

    if (this.props.dropDownPage === 'groupMembershipManagement' && this.state.searchPressed) {
      this.props.onSearch(this.state.queryString);
    }
  }

  render() {
    const {
      showDropDown,
      primaryDropDown,
      dropDownPage,
    } = this.props;
    const classes = `SearchBar ${this.props.className}`;
    return (
      <div className={classes}>
        {
          showDropDown &&
          <ResourceTypeContainer
            primary={primaryDropDown}
            onFilter={this.handleFilterSearch}
            dropDownPage={dropDownPage}
          />
        }
        <FormSearchBox
          flags={['p-stateful']}
          ref={this.searchbox}
          placeholder={this.props.placeholder}
          className="SearchBox"
          onClear={this.handleSearchClear}
          onKeyDown={this.handleKeyDown}
          queryString={this.state.queryString}
          onValueChange={this.handleSearchChange}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  queryString: PropTypes.string,
  persistQueryString: PropTypes.bool,
  isDisabled: PropTypes.bool,
  showDropDown: PropTypes.bool,
  primaryDropDown: PropTypes.bool,
  dropDownPage: PropTypes.string,
};

SearchBar.defaultProps = {
  className: '',
  placeholder: '',
  queryString: '',
  persistQueryString: false,
  isDisabled: false,
  showDropDown: false,
  primaryDropDown: true,
  dropDownPage: '',
};

export default SearchBar;
