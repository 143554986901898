// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { injectIntl } from 'react-intl';

// UI-Library
import MultiDrag from 'ui-library/lib/components/panels/multi-drag/MultiDrag';
import Spinner from 'ui-library/lib/components/general/Spinner';
import HelpHint from 'ui-library/lib/components/tooltips/HelpHint';

// Components
import './GroupMembershipManagement.css';
import GroupMembershipManagementMessages from '../GroupMembershipManagementMessages/GroupMembershipManagementMessages';
import SearchBar from '../../../search/SearchBar/SearchBar';
import RowButtons from '../RowButtons/RowButtons';
import Permission from '../../layout/Permission/Permission';

// Utils
import { PERMISSIONS } from '../../../../utils/permissions/permissions';
import { PROP_MODEL_TYPES, MODEL_TYPES } from '../../../../utils/modelTypes';
import intlShape from '../../../../utils/intlPropType';
import { getTitleOrPrimaryAttributeValue } from '../../../../utils/helpers';
import { RESOURCE_TYPES, getResourceById, iconFor } from '../../../../utils/resourceTypes';

// Store
import { addGroupMemberAction, removeGroupMemberAction } from '../../../../store/actions/groups/groups';

export const defaultLimit = 100;

export class GroupMembershipManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        {
          items: [],
          limit: defaultLimit,
        },
        {
          items: props.model.included,
          limit: defaultLimit,
        },
      ],
      membershipResultsNonMembers: [],
      groupPermissions: [],
      customColumnObj: {
        key: props.model.type,
        memberColumnName: 'Members',
        nonMemberColumnName: 'NonMembers',
      },
      dropDownPage: 'groupMembershipManagement',
    };
  }

  componentDidMount() {
    const {
      initializeMultiDrag,
      resourceTypes,
    } = this.props;

    initializeMultiDrag(this.generateColumnData());
    const filteredRTData = resourceTypes.filter(item => item.attributes.resourceType === 'group');
    if (filteredRTData) {
      const filteredGroupPermission = filteredRTData[0].attributes.permissions;
      this.setState({
        groupPermissions: [...this.state.groupPermissions, filteredGroupPermission],
      });
    }
    this.checkForCustomColumnNames();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      isFetching,
      initializeMultiDrag,
      membershipResults,
      model,
    } = this.props;
    /*
     * NOTE: We are ok to use setState here because of the conditional
     * https://reactjs.org/docs/react-component.html#componentdidupdate
     */
    /* eslint-disable react/no-did-update-set-state */
    if (prevState.customColumnObj !== this.state.customColumnObj) {
      initializeMultiDrag(this.generateColumnData());
    }
    if (
      !_.isEqual(isFetching, prevProps.isFetching) ||
      !_.isEqual(membershipResults, prevProps.membershipResults) ||
      !_.isEqual(model.included, prevProps.model.included) ||
      (membershipResults && !_.isEqual(this.state.membershipResultsNonMembers,
        membershipResults.nonMembers))
    ) {
      const newColumns = [...this.state.columns];
      const columnData = [{}, {}];

      if (membershipResults) {
        this.setState({
          membershipResultsNonMembers: membershipResults.nonMembers,
        });
        newColumns[0].items = membershipResults.nonMembers;
        newColumns[0].limit = defaultLimit;
        newColumns[1].items = membershipResults.members;
        newColumns[1].limit = defaultLimit;
        this.setState({ columns: newColumns }, () => {
          columnData[0].rows = this.formatRow(membershipResults.nonMembers);
          columnData[1].rows = this.formatRow(membershipResults.members);
          initializeMultiDrag(this.generateColumnData(columnData));
        });
      } else {
        newColumns[1].items = model.included;
        newColumns[1].limit = defaultLimit;
        this.setState({ columns: newColumns }, () => {
          columnData[1].rows = this.formatRow(model.included);
          initializeMultiDrag(this.generateColumnData(columnData));
        });
      }
    }
  }

  /*
   * The container should always be a group
   * The target could be anything - it's just what is being added or removed from the container
   */
  getContainerAndTarget = (item) => {
    const { model, profilePageModel } = this.props;
    const target = profilePageModel === MODEL_TYPES.GROUPS ? item : model;
    const container = profilePageModel === MODEL_TYPES.GROUPS ? model : item;
    return { container, target };
  };

  getEmptyLabel = (items = [], isAddable = false) => {
    const {
      intl,
      membershipResults,
      profilePageModel,
    } = this.props;

    const isUnsearched = isAddable && !membershipResults;
    const isEmptySearch = !items.length && _.get(membershipResults, 'queryString.length');

    if (isUnsearched) {
      return intl.formatMessage({
        id: `components.dual-column-search.${profilePageModel}.column.enter-search-prompt`,
      });
    } else if (isEmptySearch) {
      return intl.formatMessage({
        id: `components.dual-column-search.${profilePageModel}.column.empty-search`,
      }, {
        queryString: membershipResults.queryString,
      });
    }

    return intl.formatMessage({
      id: 'components.dual-column-search.shared.nothing-to-display',
    });
  };

  checkForCustomColumnNames = () => {
    const { resourceTypes, model } = this.props;
    const customColumnNames = resourceTypes?.filter(item => item.id === model.type)[0].attributes;
    if (customColumnNames?.labels) {
      this.setState({
        customColumnObj: {
          nonMemberColumnName: customColumnNames.labels.nonMembers,
          memberColumnName: customColumnNames.labels.members,
          key: customColumnNames?.displayName,
        },
      });
    }
  };

  generateColumnData = (data = [{}, {}]) => {
    const {
      membershipResults,
      model,
    } = this.props;

    const nonMemberRows = _.get(data, '[0].rows', membershipResults ? this.formatRow(membershipResults.nonMembers) : []);
    const memberRows = _.get(data, '[1].rows', this.formatRow(model.included));

    const columnData = [
      {
        name: this.state.customColumnObj?.nonMemberColumnName,
        rows: nonMemberRows,
        key: this.state.customColumnObj?.key,
      },
      {
        name: this.state.customColumnObj?.memberColumnName,
        rows: memberRows,
        key: this.state.customColumnObj?.key,
      },
    ];
    columnData[0].labelEmpty = this.getEmptyLabel(columnData[0].rows, true);
    columnData[1].labelEmpty = this.getEmptyLabel(columnData[1].rows, false);

    return columnData;
  };

  formatRow = (items, opts = {}) => {
    const startIndex = _.get(opts, 'startIndex', 0);
    const endIndex = _.get(opts, 'endIndex', defaultLimit);
    const resourcesCache = {};

    return items
      .slice(startIndex, endIndex)
      .filter((item) => {
        let resourceType;
        if (item.type in resourcesCache) {
          resourceType = _.get(resourcesCache, `[${item.type}].attributes.resourceType`);
        } else {
          const resource = getResourceById(item.type);
          resourceType = _.get(resource, 'attributes.resourceType');
          resourcesCache[item.type] = resource;
        }
        // Don't add the item to the result if there is no resourceType found for it
        return !!resourceType;
      })
      .map((item) => {
        const { model, modelMeta, profilePageModel } = this.props;
        const resource = resourcesCache[item.type];
        const { displayName, resourceType } = resource.attributes;
        const iconName = iconFor(resourceType);
        /*
         * Determine whether it's a group of the same type (not included) because only
         * groups can display other groups in their searches currently.
         */
        const attributeMeta = item.type === model.type
          ? modelMeta
          : _.get(modelMeta, `included[${item.type}].attributes`);
        const itemName = getTitleOrPrimaryAttributeValue(
          item.attributes,
          attributeMeta,
        );
        const { container, target } = this.getContainerAndTarget(item);

        return {
          iconName,
          id: item.id,
          name: itemName,
          modalInfo: {
            attributeMeta,
            displayName,
            item,
          },
          profileInfo: {
            container,
            profilePageModel,
            target,
          },
        };
      });
  };

  handleAdd = position => this.handleDrop({ from: position, to: { column: 1, index: 0 } });

  handleCancel = () => this.props.clearPlaceholder();

  handleDrag = position => this.props.drawPlaceholder(position.to);

  handleDrop = async (position) => {
    // Don't allow dropping into same column
    if (position.from.column !== position.to.column) {
      const {
        addGroupMember,
        columns,
        profilePageModel,
        removeGroupMember,
      } = this.props;
      const { item } = columns[position.from.column].rows[position.from.index].modalInfo;
      const { container, target } = this.getContainerAndTarget(item);

      /*
       * add member when item is being moved to column 1
       * remove member when item is being moved to column 0
       */
      try {
        if (position.to.column === 1) {
          await addGroupMember(container, [target], profilePageModel);
        } else {
          await removeGroupMember(container, [target], profilePageModel);
        }
      } catch (e) {
        // Errors handled by actions
      }
    }
  };

  handleRemove = position => this.handleDrop({ from: position, to: { column: 0, index: 0 } });

  handleSearch = (queryString = '') => {
    const {
      onSearch, profilePageModel, selectedResourceType, selectedOrgs,
    } = this.props;
    if (profilePageModel !== MODEL_TYPES.GROUPS) {
      onSearch(queryString, {
        subquery: true,
        profilePageModel,
      });
    } else {
      const selectedResourceTypeQuery = selectedResourceType.toString();
      onSearch(queryString, selectedResourceTypeQuery, selectedOrgs, {
        subquery: true,
        profilePageModel,
      }, selectedResourceTypeQuery, selectedOrgs);
    }
  };

  handleClearSearch = () => {
    const { onClearSearch, profilePageModel } = this.props;
    onClearSearch({
      subquery: true,
      profilePageModel,
    });
  };

  handleScrolledToBottom = (colIndex) => {
    const { columns } = this.state;
    const curLimit = columns[colIndex].limit;
    const newLimit = curLimit + defaultLimit;
    const newColumns = [...columns];
    newColumns[colIndex].limit = newLimit;
    const newRow = this.formatRow(
      columns[colIndex].items,
      { startIndex: curLimit, endIndex: newLimit },
    );
    this.setState({ columns: newColumns }, () => {
      this.props.appendMultiDrag(colIndex, newRow);
    });
  };

  render() {
    const {
      columns,
      intl,
      isFetching,
      membershipResults,
      model,
      placeholder,
      profilePageModel,
      secondarySelectedPermissions,
    } = this.props;
    let dropDownProps;

    if (profilePageModel !== MODEL_TYPES.GROUPS) {
      dropDownProps = {
        showDropDown: true,
        dropDownFilter: [RESOURCE_TYPES.GROUP],
        primaryDropDown: false,
      };
    }

    const renderButton = (
      {
        type,
        ...props
      },
      ControlButton,
      rowProps,
    ) => {
      // Don't display buttons for drag-drop preview
      if (rowProps.id === 'preview') return null;
      const secondaryPermissions = !secondarySelectedPermissions
        ? this.state.groupPermissions[0] : secondarySelectedPermissions;
      return (
        <RowButtons
          buttonProps={props}
          rowProps={rowProps}
          ControlButton={ControlButton}
          secondarySelectedPermissions={secondaryPermissions}
        />
      );
    };

    const contentType = props => (
      <>
        <HelpHint
          data-id="helphint-light"
          placement="top"
          type={HelpHint.types.LIGHT}
          hintText={props.name}
          delayHide={50}
        >
          <MultiDrag.MultiDragRow
            renderButton={(buttonProps, RowButton) => renderButton(buttonProps, RowButton, props)}
            {...props}
          />
        </HelpHint>
      </>
    );

    const MembershipManagement = (
      <>
        <SearchBar
          className="SearchControlItem input-width-full constant-width"
          onSearch={this.handleSearch}
          onClearSearch={this.handleClearSearch}
          placeholder={intl.formatMessage({
            id: `components.dual-column-search.${profilePageModel}.search-prompt`,
          })}
          queryString={_.get(membershipResults, 'queryString')}
          pageModel={profilePageModel}
          {...dropDownProps}
          dropDownPage={this.state.dropDownPage}
        />

        <GroupMembershipManagementMessages
          model={model}
          membershipResults={membershipResults}
          profilePageModel={profilePageModel}
        />

        <Spinner show={isFetching}>
          <MultiDrag
            stateless
            columns={columns}
            contentType={contentType}
            onAdd={this.handleAdd}
            onCancel={this.handleCancel}
            onDrag={this.handleDrag}
            onDrop={this.handleDrop}
            onRemove={this.handleRemove}
            onScrolledToBottom={this.handleScrolledToBottom}
            onSearch={this.handleSearch}
            previewMove={placeholder}
          />
        </Spinner>
      </>
    );

    if (profilePageModel === MODEL_TYPES.GROUPS) {
      return (
        <Permission
          requiredPermissions={[PERMISSIONS.MANAGE_GROUP_MEMBERSHIP]}
          resourceId={model.type}
          redirect={false}
        >
          {MembershipManagement}
        </Permission>
      );
    }

    return MembershipManagement;
  }
}

GroupMembershipManagement.propTypes = {
  addGroupMember: PropTypes.func.isRequired,
  appendMultiDrag: PropTypes.func.isRequired,
  clearPlaceholder: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    rows: PropTypes.instanceOf(Array),
  })),
  drawPlaceholder: PropTypes.func.isRequired,
  initializeMultiDrag: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isFetching: PropTypes.bool.isRequired,
  membershipResults: PropTypes.shape({
    queryString: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nonMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    meta: PropTypes.shape({
      directMembers: PropTypes.shape({}),
      indirectMembers: PropTypes.shape({}),
      nonMembers: PropTypes.shape({}),
    }).isRequired,
    error: PropTypes.string,
    groupsError: PropTypes.bool,
  }),
  selectedResourceType: PropTypes.string,
  selectedOrgs: PropTypes.string,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  modelMeta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }).isRequired,
  moveItem: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.shape({}),
  profilePageModel: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
  removeGroupMember: PropTypes.func.isRequired,
  secondarySelectedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  groupPermissions: PropTypes.arrayOf(PropTypes.string),
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

GroupMembershipManagement.defaultProps = {
  columns: [],
  membershipResults: undefined,
  placeholder: undefined,
  selectedResourceType: undefined,
  selectedOrgs: undefined,
  groupPermissions: [],
};

function mapStateToProps(state) {
  const { columns, placeholder } = state.drag;
  const { isFetching } = state.search;
  const { secondarySelected } = state.resourceTypes;

  return {
    columns,
    isFetching,
    placeholder,
    secondarySelectedPermissions: secondarySelected?.attributes?.permissions,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    addGroupMember: addGroupMemberAction,
    appendMultiDrag: MultiDrag.Actions.append,
    clearPlaceholder: MultiDrag.Actions.clearPlaceholder,
    drawPlaceholder: MultiDrag.Actions.placeholder,
    initializeMultiDrag: MultiDrag.Actions.init,
    moveItem: MultiDrag.Actions.move,
    removeGroupMember: removeGroupMemberAction,
  },
)(GroupMembershipManagement));
