import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InlineMessage, { MessageTypes } from 'ui-library/lib/components/general/InlineMessage';

import { PROP_MODEL_TYPES, MODEL_TYPES } from '../../../../utils/modelTypes';
import { isDynamicGroup, hasExceededMemberLimit, hasExceededNonMemberLimit } from '../../../../utils/helpers';

const GroupMembershipManagementMessages = (props) => {
  const {
    membershipResults,
    model,
    profilePageModel,
  } = props;
  const messages = [];
  const isDynamic = isDynamicGroup(model);
  const membersExceeded = hasExceededMemberLimit(membershipResults);
  const nonMembersExceeded = hasExceededNonMemberLimit(membershipResults);

  if (isDynamic) {
    messages.push({
      type: MessageTypes.WARNING,
      intlKey: 'components.dual-column-search.shared.dynamic-group',
    });
  }

  if (nonMembersExceeded && membersExceeded) {
    messages.push({
      type: MessageTypes.ERROR,
      intlKey: 'components.dual-column-search.shared.notice.size-limit-exceeded.both',
      values: {
        queryString: membershipResults.queryString,
      },
    });
  } else if (nonMembersExceeded || membersExceeded) {
    const resultTypes = profilePageModel === MODEL_TYPES.GROUPS ? 'all-types' : 'group-types';

    if (membersExceeded) {
      messages.push({
        type: MessageTypes.ERROR,
        intlKey: `components.dual-column-search.shared.notice.size-limit-exceeded.members.${resultTypes}`,
        values: {
          queryString: membershipResults.queryString,
        },
      });
    } else if (nonMembersExceeded) {
      messages.push({
        type: MessageTypes.ERROR,
        intlKey: `components.dual-column-search.shared.notice.size-limit-exceeded.nonmembers.${resultTypes}`,
        values: {
          queryString: membershipResults.queryString,
        },
      });
    }
  }

  if (!messages.length) return null;

  return messages.map(msg => (
    <Fragment key={msg.intlKey}>
      <InlineMessage
        bordered={false}
        fullwidth
        type={msg.type}
      >
        <FormattedMessage
          id={msg.intlKey}
          values={msg.values}
        />
      </InlineMessage>
    </Fragment>
  ));
};

GroupMembershipManagementMessages.propTypes = {
  membershipResults: PropTypes.shape({
    queryString: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nonMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    meta: PropTypes.shape({
      directMembers: PropTypes.shape({}),
      indirectMembers: PropTypes.shape({}),
      nonMembers: PropTypes.shape({}),
    }).isRequired,
    error: PropTypes.string,
    groupsError: PropTypes.bool,
  }).isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  }).isRequired,
  profilePageModel: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
};

export default GroupMembershipManagementMessages;
