import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import RowBuilder from 'ui-library/lib/components/rows/RowBuilder';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import { EXTRA_PROPERTIES } from '../../../../utils/formHelpers';

export class MultiValueField extends Component {
  state = {
    rowIds: [],
  };

  componentDidMount() {
    this.resetRows();
  }

  componentDidUpdate() {
    const { resetForm } = this.props;
    if (resetForm) {
      this.resetRows();
    }
  }

  handleRenderAddButton = () => {
    const { readOnly, rowCount } = this.props;
    const { rowIds } = this.state;
    const addButton = (
      <Button
        label="+ Add"
        inline
        onClick={this.addRow}
        type={buttonTypes.LINK}
      />
    );

    if (readOnly) return null;

    if (!_.isEmpty(rowCount)) {
      const maxCount = rowCount[EXTRA_PROPERTIES.maxCount];
      if (maxCount <= rowIds.length) {
        return null;
      }
    }
    return addButton;
  };

  displayRemoveButton = () => {
    const { readOnly } = this.props;
    const { rowIds } = this.state;

    return !readOnly && rowIds.length > 1;
  };

  addRow = () => {
    const { handleOnAdd } = this.props;
    this.setState(state => ({
      ...state,
      rowIds: [...state.rowIds, uuidV4()],
    }), handleOnAdd());
  };

  createRows = (content, ids) => ids.map((id, index) => ({
    id,
    content: content[index],
    removable: this.displayRemoveButton(index),
  }));

  removeRow = (_e, id) => {
    const { handleOnRemove } = this.props;
    const { rowIds } = this.state;
    this.setState(state => ({
      ...state,
      rowIds: state.rowIds.filter(rowId => rowId !== id),
    }), handleOnRemove(rowIds.indexOf(id)));
  };

  resetRows = () => {
    const { field } = this.props;
    this.setState(state => ({
      ...state,
      rowIds: field.map(() => uuidV4()),
    }));
  };

  render() {
    const { field } = this.props;
    const { rowIds } = this.state;

    return (
      <RowBuilder
        hasLineBetween={false}
        renderAddButton={this.handleRenderAddButton}
        onAdd={this.addRow}
        onRemove={this.removeRow}
        rows={[
            ...this.createRows(
                field,
                rowIds,
            ),
        ]}
      />
    );
  }
}

MultiValueField.propTypes = {
  field: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleOnAdd: PropTypes.func.isRequired,
  handleOnRemove: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  resetForm: PropTypes.bool.isRequired,
  rowCount: PropTypes.oneOfType([
    PropTypes.shape({
      'X-MIN-VALUE-COUNT': PropTypes.number,
      'X-MAX-VALUE-COUNT': PropTypes.number,
    }),
    PropTypes.shape({}),
  ]).isRequired,
};

export default MultiValueField;
