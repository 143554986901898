// Vendors
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';

// UI-Library
import RockerButton from 'ui-library/lib/components/forms/RockerButton';
import LeftNavBarActions from 'ui-library/lib/components/panels/left-nav/Actions';
import FormDropDownList from 'ui-library/lib/components/forms/FormDropDownList';
import InputRow from 'ui-library/lib/components/layout/InputRow';
import ExpandableRow from 'ui-library/lib/components/rows/expandable-row/ExpandableRow';
import CollapsibleDivider from 'ui-library/lib/components/layout/CollapsibleDivider';
import Button, { buttonTypes } from 'ui-library/lib/components/buttons/Button';
import InlineMessage from 'ui-library/lib/components/general/InlineMessage';

// Components
import Permission, { LOGIC_OPERATORS } from '../../layout/Permission/Permission';
import Page from '../../layout/Page/Page';
import GroupMembershipManagement from '../../membership-management/GroupMembershipManagement/GroupMembershipManagement';
import ModelSummary from '../../summaries/ModelSummary/ModelSummary';
import './EditModelPage.css';
import ModelFormV2 from '../../forms/ModelFormV2/ModelFormV2';
import ReferenceContainer from '../../../../containers/ReferenceContainer/ReferenceContainer';
import ConfirmationTooltip from '../../buttons/ConfirmationTooltip/ConfirmationTooltip';
import ModalWithoutButton from '../../modals/ModalWithoutButton/ModalWithoutButton';
import EditModal from '../../modals/EditModal/EditModal';

// Store
import { filterResourceTypesAction,
  getSelectedParentResourceAction,
  setSelectedResourceTypeAction }
  from '../../../../store/actions/resourceTypes/resourceTypes';
import { deleteCorrelatedUserAction } from '../../../../store/actions/userActions';
import { deleteCorrelatedGroupAction } from '../../../../store/actions/groups/groups';
import { deleteCorrelatedGenericAction } from '../../../../store/actions/generics/index';

// Utils
import { HTTP_ERRORS,
  getNonBlacklistedAttributes,
  getPrimarySearchDisplayName,
  correlatedResourceFormatter } from '../../../../utils/helpers';
import permissions, { PERMISSIONS } from '../../../../utils/permissions/permissions';
import { PROP_MODEL_TYPES, MODEL_TYPES } from '../../../../utils/modelTypes';
import * as routes from '../../../../utils/routes';
import { RESOURCE_TYPES } from '../../../../utils/resourceTypes';
import intlShape from '../../../../utils/intlPropType';
import PillButtonWithConfirmation from '../../buttons/PillButtonWithConfirmation/PillButtonWithConfirmation';

const NONEOPTION = { label: '-- ALL --', value: '' };
export class EditModelPage extends Component {
  constructor(props) {
    super(props);

    this.labels = [
      props.intl.formatMessage({ id: 'components.rocker-button.profile' }),
      props.intl.formatMessage({ id: 'components.rocker-button.groups' }),
    ];

    this.dropDownLabel = props.intl.formatMessage({ id: 'components.search-bar-drop-down.label' });

    this.handleEdit = this.handleEdit.bind(this);
    this.handleButtonChange = this.handleButtonChange.bind(this);
    this.displayContent = this.displayContent.bind(this);
    this.groupsContent = this.groupsContent.bind(this);
    this.profileContent = this.profileContent.bind(this);
    this.displayRocker = this.displayRocker.bind(this);
    this.handleCorrelatedResourceSave = this.handleCorrelatedResourceSave.bind(this);

    let selectedIndex = 0;
    if (!props.canUpdateModel && props.canManageMembershipForModel) selectedIndex = 1;

    this.state = {
      selectedIndex,
      selectedOption: NONEOPTION,
      showSecondDropDown: false,
      optionsMenu2: [],
      secondSelectedOption: NONEOPTION,
      orgsWithId: '',
      editedResourceType: '',
      parentResourceDisplayName: '',
      referencePermission: false,
      showCorrelatedResources: false,
      isDeleting: false,
      isConfirmDeleteOpen: false,
      isEditModalOpen: '',
      showConfirmationTooltip: '',
      confirmationTooltip: {},
      editModal: {},
      editModalResourceType: '',
      correlateSearchLimitError: '',
      isLoading: false,
    };
  }

  componentDidMount() {
    const { error } = this.props;
    if (error === HTTP_ERRORS[404]) {
      this.notFound();
    }
    this.checkReferencePermission();
    this.checkCorrelatedResources();
    this.checkLinkEditPermissions();
    this.checkResourceTypeEditPermissions();
    this.checkResourceTypeDeletePermissions();
    this.checkCorrelatedAttributeError();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.model !== this.props.model) {
      this.checkCorrelatedAttributeError();
    }
  }

  notFound = () => <Redirect to={routes.NOT_FOUND_ROUTE} />;

  checkReferencePermission = () => {
    const { resourceTypes } = this.props;
    const filteredRTData = resourceTypes.filter(item => item.id === 'groups');
    if (filteredRTData.length) {
      if (Object.prototype.hasOwnProperty.call(filteredRTData[0].attributes, 'permissions')) {
        const filteredGroupPermission = filteredRTData[0].attributes.permissions;
        const referenceGroupPermission = filteredGroupPermission.filter(permission => permission === 'reference');
        if (referenceGroupPermission.length) {
          this.setState({ referencePermission: true });
        }
      }
    }
  };

  checkCorrelatedResources = () => {
    const { modelMeta } = this.props;

    const hasCorrelatedResources = modelMeta.attributes &&
      Object.values(modelMeta.attributes).some(attr => attr.isCorrelatedResource);

    if (modelMeta.included && hasCorrelatedResources) {
      this.setState({ showCorrelatedResources: true });
    }
  };

  filterResourceTypes() {
    this.checkReferencePermission();
    const filter = {
      byType: [RESOURCE_TYPES.GROUP],
      byPermission: this.state.referencePermission ? PERMISSIONS.REFERENCE : PERMISSIONS.READ,
      primary: false,
    };
    this.props.filterResourceTypesAction(filter);
  }

  handleButtonChange(e) {
    const selectedIndex = _.get(e, 'index', 0);
    const { canManageMembershipForModel } = this.props;

    if (selectedIndex === 1 && canManageMembershipForModel) {
      this.filterResourceTypes();
    }

    this.setState({ selectedIndex });
  }

  async handleEdit(updatedAttrs) {
    const { id } = this.props.model;

    try {
      await this.props.editModelAction(id, updatedAttrs);
    } catch (error) {
      // Errors handled by actions
    }
  }

  checkLinkEditPermissions() {
    const { modelMeta } = this.props;
    const editPermissions = [PERMISSIONS.UPDATE, PERMISSIONS.UPDATE_PROFILE];
    const canEditLinks = {};

    Object.keys(modelMeta.attributes).forEach((attrName) => {
      const attr = modelMeta.attributes[attrName];

      const {
        isCorrelatedResource,
        correlationAttributes,
        correlatedResourceType,
      } = attr;

      if (isCorrelatedResource) {
        if (correlationAttributes.useSecondaryValueForLinking) {
          canEditLinks[attrName] = true;
        } else {
          canEditLinks[attrName] = permissions.hasPermissions(editPermissions, correlatedResourceType, 'some');
        }
      }
    });

    this.setState({ canEditLinks });
  }

  handleCorrelatedResourceSave() {
    const { getModelType } = this.props;
    this.setState({ isEditModalOpen: '', editModal: {}, editModalResourceType: '' });
    getModelType();
  }

  checkResourceTypeEditPermissions() {
    const { modelMeta: { attributes } } = this.props;
    const canEditResourceType = {};
    const updatePermissions = [PERMISSIONS.UPDATE, PERMISSIONS.UPDATE_PROFILE];

    Object.keys(attributes).forEach((type) => {
      const resourceType = attributes[type];
      if (resourceType.isCorrelatedResource) {
        canEditResourceType[type] = permissions.hasPermissions(updatePermissions, resourceType.correlatedResourceType, 'some');
      }
    });

    this.setState({ canEditResourceType });
  }
  checkResourceTypeDeletePermissions() {
    const { modelMeta: { attributes } } = this.props;
    const canDeleteResourceType = {};
    const deletePermissions = PERMISSIONS.DELETE;

    Object.keys(attributes).forEach((type) => {
      const resourceType = attributes[type];
      if (resourceType.isCorrelatedResource) {
        canDeleteResourceType[type] = permissions
          .hasPermission(deletePermissions, resourceType.correlatedResourceType);
      }
    });

    this.setState({ canDeleteResourceType });
  }
  checkCorrelatedAttributeError() {
    const { model } = this.props;
    const correlateSearchLimitError = model.meta?.attributeErrors &&
      Object.entries(model.meta?.attributeErrors)[0][1].title;
    if (model.meta?.attributeErrors && correlateSearchLimitError) {
      this.setState({ correlateSearchLimitError });
    }
  }

  profileContent() {
    const {
      canUpdateModel,
      model,
      modelMeta,
      referenceErrors,
      resourceTypes,
      selected,
      intl,
      messages,
    } = this.props;
    const {
      isConfirmDeleteOpen,
      isDeleting,
    } = this.state;

    if (!canUpdateModel) {
      return null;
    }

    const handleExpanse = (dividerId) => {
      this.setState({
        [dividerId]: !this.state[dividerId],
      });
    };

    const modelTypeFilter = (item) => {
      const filteredType = resourceTypes
        .filter(resType => resType.attributes.resourceEndpoint.toLowerCase() ===
          item.correlatedResourceType.toLowerCase());
      const corResModelType = `${filteredType[0].attributes.resourceType}s`;
      return corResModelType;
    };

    return (
      <div className="profileContent">
        <div className="modelForm">
          <ModelFormV2
            areFieldsValid={_.isEmpty(referenceErrors)}
            meta={modelMeta}
            model={model}
            onSave={this.handleEdit}
            selected={selected}
            resourceTypes={resourceTypes}
          />
        </div>
        {this.state.showCorrelatedResources ?
          <div>
            <h2>{intl.formatMessage({ id: 'components.editModelPage.correlatedResources.sectionTitle' })}</h2>
            <br />
            {correlatedResourceFormatter(model, modelMeta, resourceTypes).map(item => (
              <div key={item.title}>
                <CollapsibleDivider
                  title={item.title}
                  onToggle={() => handleExpanse(item.title)}
                  open={this.state[item.title]}
                />
                {this.state[item.title] &&
                  <>
                    { this.state.canEditLinks[item.id] && <ReferenceContainer
                      SearchDropdownProps={{
                        displayName: intl.formatMessage({ id: 'components.editModelPage.correlatedResources.searchBar' }, { resourceType: item.title }),
                        handleReferenceClick: (itm, option, result) => {
                          if (option) {
                            this.setState({
                              showConfirmationTooltip: item.title,
                              confirmationTooltip: {
                                item: itm,
                                option,
                                result,
                              },
                            });
                          }
                        },
                        referenceResourceType: item.correlatedResourceType,
                        labelHelpText: intl.formatMessage({ id: 'components.editModelPage.correlatedResources.labelHelpText' }, { resourceType: item.linkedResourceType }),
                        name: item.title,
                      }}
                      resourceType={item.correlatedResourceType}
                      resourceTypes={resourceTypes}
                      readOnly={false}
                      resetForm={false}
                    />}
                    { this.state.correlateSearchLimitError && (
                      <InlineMessage type={InlineMessage.MessageTypes.ERROR}>
                        {this.state.correlateSearchLimitError}
                      </InlineMessage>)}
                    <div>{ (item.correlatedResources.length === 0 &&
                    !this.state.correlateSearchLimitError) ?
                      <InlineMessage type={InlineMessage.MessageTypes.NOTICE}>
                        {intl.formatMessage({ id: 'components.editModelPage.correlatedResources.noLinkedResources' }, { resourceType: item.title })}
                      </InlineMessage>
                        :
                        item.correlatedResources.map(res => (
                          <ExpandableRow
                            title={res.title}
                            key={res.id}
                            showEdit={this.state.canEditResourceType[item.id]}
                            onEditButtonClick={() => {
                              this.setState({
                                isEditModalOpen: res.id,
                                editModalResourceType: item.title,
                                editModal: {
                                  resourceType: item.displayName,
                                  value: { ...res, type: item.correlatedResourceType },
                                },
                              });
                            }}
                            onDeleteCancelClick={() => {
                              this.setState({ isConfirmDeleteOpen: false });
                            }}
                            onDeleteConfirmClick={async () => {
                              const corResModelType = modelTypeFilter(item);
                              const deleteCorResAction = {
                                [MODEL_TYPES.GENERICS]: this.props.deleteCorrelatedGenericAction,
                                [MODEL_TYPES.USERS]: this.props.deleteCorrelatedUserAction,
                                [MODEL_TYPES.GROUPS]: this.props.deleteCorrelatedGroupAction,
                              };
                              this.setState({ isDeleting: true });
                              const corResType = item.model[0].type;
                              const { profilePageModel } = this.props;
                              const resp = await
                                deleteCorResAction[corResModelType](
                                  res.id, corResType, item.displayName, profilePageModel, model);
                              if (resp === 'success') {
                                this.setState({ isConfirmDeleteOpen: false, isDeleting: false });
                              } else {
                                this.setState({ isDeleting: false });
                              }
                            }}

                            onDelete={() => {
                              this.setState({ isConfirmDeleteOpen: res.id });
                            }}
                            showDeleteConfirm={isConfirmDeleteOpen === res.id}
                            confirmDeleteTitle={intl.formatMessage({ id: 'common.delete' })}
                            confirmDeleteContent={({
                              onCancel,
                              onConfirm,
                              confirmLabel,
                              cancelLabel,
                            }) => (
                              <div className="delete-tooltip">
                                <p><FormattedMessage id="actions.delete.confirm" /></p>
                                <div className="delete-tooltip__buttons">
                                  <Button
                                    label={cancelLabel}
                                    type={buttonTypes.CANCEL}
                                    onClick={onCancel}
                                  />
                                  <Button
                                    label={confirmLabel}
                                    type={buttonTypes.DANGER}
                                    onClick={onConfirm}
                                    loading={isDeleting}
                                    disabled={isDeleting}
                                  />
                                </div>
                              </div>
                            )}
                            showDelete={this.state.canDeleteResourceType[item.id]}
                            rowAccessories={
                              this.state.canEditLinks[item.id] && <PillButtonWithConfirmation
                                buttonLabel="Unlink"
                                confirmationMessage={intl.formatMessage({ id: 'components.editModelPage.correlatedResources.unlinkConfirmation' }, { objectId: res.title })}
                                confirmationTitle={intl.formatMessage({ id: 'components.editModelPage.correlatedResources.unlinkTitle' }, { resourceType: item.title })}
                                confirmationButtonType="danger"
                                onConfirm={() => this.props.onUnlink(item, res)}
                              />
                            }
                          >
                            {res.includeInSummaryAttributes.map(summaryAtt => (
                              <p key={summaryAtt.key}>
                                <span className="summaryAtt">{summaryAtt.displayName}</span> : {summaryAtt.val}
                              </p>),
                              )}
                            {this.state.isEditModalOpen === res.id
                              && this.state.editModalResourceType === item.title
                              && (
                              <ModalWithoutButton
                                modalTitle={{
                                  id: 'components.modal.title',
                                  title: 'Edit',
                                }}
                                openModal
                                onCloseModal={() => this.setState({ isEditModalOpen: '', editModal: {}, editModalResourceType: '' })}
                              >
                                <EditModal
                                  handleSave={this.handleCorrelatedResourceSave}
                                  resourceType={this.state.editModal.resourceType}
                                  resourceTypes={resourceTypes}
                                  selected={selected}
                                  value={this.state.editModal.value}
                                  messages={messages}
                                />
                              </ModalWithoutButton>
                            )}
                          </ExpandableRow>),
                        )}
                    </div>
                    {this.state.showConfirmationTooltip === item.title && (
                      <ConfirmationTooltip
                        confirmationMessage={intl.formatMessage(
                          {
                            id: 'components.editModelPage.correlatedResources.linkConfirmation',
                          },
                          {
                            objectId: this.state.confirmationTooltip.option.label,
                            resourceType: _.upperFirst(item.currentEntry),
                          },
                        )}
                        confirmationTitle={intl.formatMessage({ id: 'components.editModelPage.correlatedResources.searchBar' }, { resourceType: this.state.confirmationTooltip.item })}
                        confirmationButtonType="danger"
                        onConfirm={() => {
                          this.props.onLink(item, this.state.confirmationTooltip.result);
                          this.setState({
                            showConfirmationTooltip: '',
                          });
                        }}
                        onCancel={() => this.setState({ showConfirmationTooltip: '' })}
                        open={this.state.showConfirmationTooltip === item.title}
                      />
                    )}
                  </>
                }
              </div>),
            )}
          </div> :
          null }
        <br />
      </div>
    );
  }

  groupsContent() {
    const {
      profilePageModel,
      model,
      modelMeta,
      modelDisplayAttributeValue,
      membershipResults,
      onSearch,
      onClearSearch,
      resourceTypes,
      canManageMembershipForModel,
      showAttributesOnMembershipManagement,
      intl,
    } = this.props;

    if (!canManageMembershipForModel && !this.state.referencePermission) {
      return (
        <div className="groups-content no-groups">
          <FormattedMessage id="components.accordion-profile.no-groups-permission" />
        </div>
      );
    }
    let groupMembershipDropDown = '';
    if (profilePageModel === 'groups' && this.state.selectedIndex === 1) {
      /*
       * map out the available options for the resourceType drop down menu
       * and capitalize the first letter of each option
       */

      const optional = resourceTypes.filter(resourceTypeItem => resourceTypeItem.id);

      const optionMenu1 = optional.map(resourceTypeItem => ({
        value: resourceTypeItem.attributes.displayName,
        id: resourceTypeItem.id,
      }));
      const handleValueChange = (option, queryString = '') => {
        this.setState({
          selectedOption: option,
          secondSelectedOption: NONEOPTION,
        });
        if (option === NONEOPTION) {
          this.setState({
            editedResourceType: '',
            orgsWithId: '',
            showSecondDropDown: false,
          });
          onSearch(queryString, '', '', true);
          return;
        }

        const filteredResourceType = resourceTypes
          .filter(resourceType => resourceType.attributes.displayName === option.value)[0];
        // We need to change the displayName for resourceType back to the resource endpoint
        const { resourceEndpoint } = filteredResourceType.attributes;
        this.setState({
          editedResourceType: resourceEndpoint,
        });

        onSearch(queryString, resourceEndpoint, '', true);

        /*
         *if statement sets whether or not we see the second drop down menu
         *else statement resets state so the 2nd menu is not displayed if another option is chosen
         */
        if (filteredResourceType.attributes.parentResourceType) {
          const parentDisplayName = getPrimarySearchDisplayName(
            modelMeta, filteredResourceType.attributes.parentResourceType);
          this.props.getSelectedParentResourceAction(filteredResourceType, true)
            .then((response) => {
              const { parentResourceType } = filteredResourceType.attributes;
              const secondDropDownMenuOptions = response.data.map(item => ({
                value: item.attributes[parentDisplayName][0],
                orgAndId: `${item.type}/${item.id}`,
              }));
              const parentResourceDisplayName = optional
                .find(item => parentResourceType === item.id)?.attributes.displayName;
              this.setState({
                optionsMenu2: secondDropDownMenuOptions,
                parentResourceDisplayName,
              });
            });
          this.setState({
            showSecondDropDown: true,
          });
        } else {
          this.setState({
            showSecondDropDown: false,
            secondSelectedOption: {},
            orgsWithId: '',
          });
        }
      };


      // only show this drop down menu if the edit page has ModelType equal to 'Group'
      groupMembershipDropDown = (
        <InputRow>
          {resourceTypes && (<FormDropDownList
            options={optionMenu1}
            autofocus
            label={this.dropDownLabel}
            noneOption={NONEOPTION}
            selectedOption={this.state.selectedOption}
            onValueChange={event => handleValueChange(event)}
          />)}
        </InputRow>
      );
    }

    // handleChange for second drop down menu
    const handleSecondValueChange = (option) => {
      this.setState({
        secondSelectedOption: option,
        orgsWithId: option.orgAndId,
      });
    };

    const filteredAttributes = getNonBlacklistedAttributes(modelMeta.attributes, model.attributes);
    const AttributesComponent =
      showAttributesOnMembershipManagement
      && filteredAttributes.length > 1
      && (
        <>
          <ModelSummary
            attributes={model.attributes}
            meta={modelMeta}
          />

          <hr className="page-body_divider" />
        </>
      );

    const ManagementComponent = (
      <GroupMembershipManagement
        profilePageModel={profilePageModel}
        onSearch={onSearch}
        onClearSearch={onClearSearch}
        model={model}
        modelMeta={modelMeta}
        modelDisplayAttributeValue={modelDisplayAttributeValue}
        membershipResults={membershipResults}
        selectedResourceType={this.state.editedResourceType}
        selectedOrgs={this.state.orgsWithId}
        resourceTypes={resourceTypes}
      />
    );

    return (
      <>
        {AttributesComponent}
        {groupMembershipDropDown}
        {this.state.showSecondDropDown ?
          (
            <InputRow>
              {resourceTypes && (
              <FormDropDownList
                autofocus
                options={this.state.optionsMenu2}
                noneOption={NONEOPTION}
                selectedOption={this.state.secondSelectedOption}
                onValueChange={event => handleSecondValueChange(event)}
                label={intl.formatMessage({ id: 'containers.model-form.generics-dropdown-label' }, { displayName: this.state.parentResourceDisplayName })}
              />)}
            </InputRow>) : '' }
        {ManagementComponent}
      </>
    );
  }

  displayContent() {
    const { selectedIndex } = this.state;

    if (selectedIndex === 0) {
      return this.profileContent();
    } else if (selectedIndex === 1) {
      return this.groupsContent();
    }

    return null;
  }

  displayRocker() {
    return (
      <div className="profile-switch">
        <RockerButton
          selectedIndex={this.state.selectedIndex}
          onValueChange={this.handleButtonChange}
          labels={this.labels}
        />
      </div>
    );
  }

  render() {
    const {
      profilePageModel,
      pageHeaderChildren,
      model,
      modelDisplayAttributeValue,
      breadcrumbPath,
      children,
      canManageMembershipForModel,
    } = this.props;
    const requiredPermissions = [PERMISSIONS.UPDATE, PERMISSIONS.UPDATE_PROFILE];
    if (profilePageModel === MODEL_TYPES.GROUPS) {
      requiredPermissions.push(PERMISSIONS.MANAGE_GROUP_MEMBERSHIP);
    }
    const rockerComponentCheck = (canManageMembershipForModel || this.state.referencePermission)
      ? this.displayRocker() : null;

    return (
      <Permission
        requiredPermissions={requiredPermissions}
        resourceId={model.type}
        permissionsLogic={LOGIC_OPERATORS.OR}
      >
        <Page
          className="EditModelPage"
          breadcrumb={{
            path: breadcrumbPath,
            label: (<FormattedMessage id="breadcrumbs.page.search" />),
          }}
          title={modelDisplayAttributeValue}
          pageHeaderChildren={pageHeaderChildren}
        >
          {rockerComponentCheck}

          <div className="page-body">
            {children}

            {this.displayContent()}
          </div>
        </Page>
      </Permission>
    );
  }
}

EditModelPage.propTypes = {
  breadcrumbPath: PropTypes.string.isRequired,
  canManageMembershipForModel: PropTypes.bool,
  canUpdateModel: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  editModelAction: PropTypes.func.isRequired,
  error: PropTypes.string,
  filterResourceTypesAction: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  model: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      attributeErrors: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
    attributes: PropTypes.shape({
      Documents: PropTypes.arrayOf(PropTypes.shape({
        attributes: PropTypes.shape({}),
        id: PropTypes.string.isRequired,
      })),
    }).isRequired,
    included: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  modelDisplayAttributeValue: PropTypes.string,
  modelMeta: PropTypes.shape({
    attributes: PropTypes.shape({}).isRequired,
    included: PropTypes.shape({}),
  }),
  membershipResults: PropTypes.shape({
    queryString: PropTypes.string.isRequired,
    members: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    nonMembers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    meta: PropTypes.shape({
      directMembers: PropTypes.shape({}),
      indirectMembers: PropTypes.shape({}),
      nonMembers: PropTypes.shape({}),
    }).isRequired,
    error: PropTypes.string,
    groupsError: PropTypes.bool,
  }),
  onClearSearch: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  pageHeaderChildren: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  profilePageModel: PropTypes.oneOf(PROP_MODEL_TYPES).isRequired,
  referenceErrors: PropTypes.shape({}).isRequired,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  getSelectedParentResourceAction: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      parentResourceType: PropTypes.string,
    }).isRequired,
  }).isRequired,
  showAttributesOnMembershipManagement: PropTypes.bool,
  onUnlink: PropTypes.func.isRequired,
  deleteCorrelatedGenericAction: PropTypes.func.isRequired,
  deleteCorrelatedGroupAction: PropTypes.func.isRequired,
  deleteCorrelatedUserAction: PropTypes.func.isRequired,
  onLink: PropTypes.func.isRequired,
  getModelType: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(PropTypes.shape()),
};

EditModelPage.defaultProps = {
  canManageMembershipForModel: true,
  canUpdateModel: true,
  children: undefined,
  error: undefined,
  membershipResults: undefined,
  model: undefined,
  modelDisplayAttributeValue: undefined,
  modelMeta: undefined,
  pageHeaderChildren: undefined,
  showAttributesOnMembershipManagement: false,
  messages: [],
};

function mapStateToProps(state) {
  const { data, selected } = state.resourceTypes;
  const { reference: { errors } } = state.modelForm;

  return {
    referenceErrors: errors,
    resourceTypes: data,
    selected,
    messages: state.messageContainer.messages,
  };
}

export default injectIntl(connect(
  mapStateToProps,
  {
    selectItemLeftNavAction: LeftNavBarActions.selectItem,
    filterResourceTypesAction,
    getSelectedParentResourceAction,
    setSelectedResourceTypeAction,
    deleteCorrelatedGenericAction,
    deleteCorrelatedGroupAction,
    deleteCorrelatedUserAction,
  },
)(EditModelPage));
