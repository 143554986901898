// Vendors
import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import LeftNavBarActions from 'ui-library/lib/components/panels/left-nav/Actions';

// Components
import * as types from './actionTypes';
import {
  createAction,
  FAILURE,
  REQUEST,
  SUCCESS,
} from './index';
import { getGenericAction } from './generics';
import { getGroupAction } from './groups/groups';

// Utils
import endpoints from '../../utils/apiEndpoints';
import {
  dispatchSuccessMessage,
  dispatchErrorMessage,
  generateHttpErrorMessage,
  generateAttributeErrors,
  logErrorToConsole,
} from '../../utils/helpers';
import * as routes from '../../utils/routes';
import axios from '../../utils/axiosInstance';
import { RESOURCE_TYPES } from '../../utils/resourceTypes';
import generateModelFormAttributesPayload from '../../utils/storeHelpers';

export const userActions = {
  generatePasswordRequest: data => createAction(types.GENERATE_PASSWORD[REQUEST], { data }),
  generatePasswordSuccess: data => createAction(types.GENERATE_PASSWORD[SUCCESS], { data }),
  generatePasswordFailure: data => createAction(types.GENERATE_PASSWORD[FAILURE], { data }),
};


export function getUserAction(id, type, secondarySelectedResourceEndpoint) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints
        .endpointForResource(type, { id, resourceType: secondarySelectedResourceEndpoint });
      const res = await axios.get(endpoint);

      dispatch({
        type: types.GET_USER,
        data: {
          user: res.data.data,
          included: res.data.included,
          meta: res.data.meta,
        },
      });
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatch({
        type: types.GET_USER_ERROR,
        data: {
          user: {
            id,
            type,
          },
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
    }
  };
}

/**
 * @param {object} attributes contains data for all attributes
 * @param {object} relationshipOptions contains data values for selected relationship options
 * e.g. {
 *   genericDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   },
 *   groupDropdown: {
 *     id: '',
 *     label: '',
 *     type: '',
 *     value: ''
 *   }
 * }
 * @param {boolean} skipMessage whether the global app message should be skipped
 * @returns {Promise} resolved or rejected promise
 */
export function createUserAction(
  attributes,
  relationshipOptions,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      const selected = _.get(getState(), 'resourceTypes.create.selected');
      const resourceId = _.get(selected, 'id');
      const endpoint = endpoints.endpointForResource(resourceId);
      const payload = generateModelFormAttributesPayload(attributes, relationshipOptions);

      dispatch({
        type: types.CREATE_USER_TRIGGERED,
        data: {
          payload,
        },
      });

      const res = await axios.post(endpoint, {
        data: payload,
      });

      dispatch({
        type: types.CREATE_USER_SUCCESS,
        data: {
          user: res.data.data,
        },
      });

      // Set the left nav bar to not be on "Create User" item
      dispatch(LeftNavBarActions.selectItem(routes.USERS_SEARCH_ROUTE));

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.create.success"
            values={{
              resourceType: _.capitalize(RESOURCE_TYPES.USER),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch({
        type: types.CREATE_USER_ERROR,
        data: {
          error: intlKey,
          attributeErrors,
        },
      });

      dispatch({
        type: types.MODEL_FORM_ERROR,
        data: {
          error: intlKey,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function editUserAction(
  id,
  updatedAttributes,
  skipMessage = false,
) {
  return async (dispatch, getState) => {
    try {
      let extraInfo = {};
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      const hasNewPassword = 'userPassword' in updatedAttributes;
      const endpoint = endpoints.endpointForResource(resourceId, { id, password: hasNewPassword });
      const res = await axios.patch(endpoint, {
        data: {
          attributes: {
            ...updatedAttributes,
          },
        },
      });

      const resData = _.get(res, 'data.data');
      const resId = resData.id;
      const resAttributes = resData.attributes;

      if (_.isUndefined(resAttributes) || _.isUndefined(resId)) throw new Error();

      if (resId !== id) extraInfo = { ...extraInfo, newId: resId };
      if (hasNewPassword) {
        extraInfo = { ...extraInfo, hasNewPassword };
        resAttributes['dadmin-account-locked'] = [false];
      }

      dispatch({
        type: types.USER_UPDATED,
        data: {
          id,
          updatedAttributes: resAttributes,
          ...extraInfo,
        },
      });

      if (!skipMessage) {
        dispatchSuccessMessage(
          dispatch,
          <FormattedMessage
            id="actions.update.success"
            values={{
              resourceType: _.capitalize(RESOURCE_TYPES.USER),
            }}
          />,
        );
      }

      return Promise.resolve(res);
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;
      const attributeErrors = generateAttributeErrors(error);

      dispatch({
        type: types.USER_UPDATED_ERROR,
        data: {
          error: intlKey,
          attributeErrors,
        },
      });

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function deleteUserAction(id, type) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: _.capitalize(RESOURCE_TYPES.USER),
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}

export function deleteCorrelatedUserAction(id, type, _displayName, pageModel, model) {
  return async (dispatch) => {
    try {
      const endpoint = endpoints.endpointForResource(type, { id });
      await axios.delete(endpoint);

      // Updating the store for correlated resource users linked to the generics or groups type
      if (pageModel === 'generics') {
        await dispatch(getGenericAction(model.id, model.type));
      } else {
        await dispatch(getGroupAction(model.id, model.type));
      }

      dispatchSuccessMessage(
        dispatch,
        <FormattedMessage
          id="actions.delete.success"
          values={{
            resourceType: _.capitalize(RESOURCE_TYPES.USER),
          }}
        />,
      );
      return 'success';
    } catch (error) {
      logErrorToConsole(error);
      const errorMessage = generateHttpErrorMessage(error);
      const { intlKey, statusCode } = errorMessage;

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
          values={{
            statusCode,
          }}
        />,
      );
      return 'error';
    }
  };
}

export function generatePasswordAction(userId) {
  return async (dispatch, getState) => {
    try {
      const resourceId = _.get(getState(), 'resourceTypes.selected.id');
      const endpoint = endpoints.endpointForResource(resourceId, { id: userId, password: true });

      dispatch(userActions.generatePasswordRequest());

      const result = await axios.post(endpoint, {});

      const generatedPasswords = _.get(result, 'data.data.attributes.generatedPasswords', []);
      const lastAttempt = generatedPasswords[generatedPasswords.length - 1];
      const validPassword = _.get(lastAttempt, 'generatedPassword');

      if (lastAttempt.validationErrors && lastAttempt.validationErrors.length) {
        throw new Error();
      }

      dispatch(userActions.generatePasswordSuccess({
        id: userId,
        validPassword,
        ...result.data.data,
      }));

      return validPassword;
    } catch (error) {
      logErrorToConsole(error);
      const intlKey = 'custom-errors.generate-password';

      dispatch(userActions.generatePasswordFailure({
        error: intlKey,
      }));

      dispatchErrorMessage(
        dispatch,
        <FormattedMessage
          id={intlKey}
        />,
      );

      return Promise.reject(error);
    }
  };
}

export function clearGeneratePasswordAction() {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_GENERATE_PASSWORD });
  };
}

export function clearNewUserAction() {
  return async (dispatch) => {
    dispatch({ type: types.CLEAR_NEW_USER });
  };
}
