/* eslint-disable prefer-arrow-callback */
import _ from 'lodash';
import * as Yup from 'yup';
import { RESOURCE_TYPES } from './resourceTypes';
import * as helpers from './helpers';

export const FIELD_TYPES = {
  BOOLEAN: 'boolean',
  DATETIME: 'dateTime',
  INTEGER: 'integer',
  REFERENCE: 'reference',
  STRING: 'string',
};

const defaultMap = {
  [FIELD_TYPES.BOOLEAN]: false,
  [FIELD_TYPES.DATETIME]: '',
  [FIELD_TYPES.INTEGER]: '',
  [FIELD_TYPES.REFERENCE]: null,
  [FIELD_TYPES.STRING]: '',
};

export const YUP_TYPES = {
  boolean: 'boolean',
  dateTime: 'date',
  integer: 'number',
  reference: 'string',
  string: 'string',
};

export const EXTRA_PROPERTIES = {
  minCount: 'X-MIN-VALUE-COUNT',
  maxCount: 'X-MAX-VALUE-COUNT',
};

/**
 * Determine what the initial status object should be. See Formik documentation on the
 * "mapPropsToStatus" function for more information.
 *
 * @param {{}} props The props supplied to the wrapped component
 * @returns {{}} The object containing the custom status information for Formik
 */
export function generateModelFormStatus(props) {
  const { attributes } = props.meta;
  const customValidation = (
    Object
      .entries(attributes)
      .reduce((result, [key, val]) => {
        if (val.customPresentation) return { ...result, [key]: true };
        return result;
      }, {})
  );

  return { customValidation };
}

const setMinCountValues = (minCount, dataType, value) => {
  let min = Number(minCount);
  // In case the minCount value is set to 0 in the admin console
  min = min === 0 ? min += 1 : min;
  const vals = [];

  if (value) return value;

  for (let i = 0; i < min; i += 1) {
    vals.push(defaultMap[dataType]);
  }

  return vals;
};

/**
 * Determine whether the password field should display. It should display if there is no existing
 * model and if the model being created is a user resource.
 *
 * @param {{}} props The props object used to determine what type of model is being acted upon
 * @returns {boolean} Whether or not the password field should be shown in the form
 */
function showPasswordField(props) {
  return !props.model.id && _.get(props, 'meta.resourceType') === RESOURCE_TYPES.USER;
}

/**
 * Determine what the initial values for the form should be. See Formik documentation on the
 * "mapPropsToValues" function for more information.
 *
 * @param {{}} props The props supplied to the wrapped component
 * @returns {{}} The object containing the values for Formik
 */
export function generateModelFormValues(props) {
  const hasModel = !!(props.model.id);
  const attributes = _.get(props, 'meta.attributes');
  const extraAttributes = showPasswordField(props)
    ? { userPassword: '' }
    : {};
  const blacklist = props.blacklist || helpers.blacklistedDisplayAttrs;

  const values = Object
    .keys(attributes)
    .filter(key => !blacklist.includes(key))
    .reduce((result, current) => {
      const {
        dataType,
        extraProperties,
        readOnly,
      } = attributes[current];

      if (!hasModel && readOnly) return result;

      if (hasModel) {
        const value = _.get(props, `model.attributes[${current}]`);

        if (extraProperties[EXTRA_PROPERTIES.minCount]) {
          const minCount = extraProperties[EXTRA_PROPERTIES.minCount][0];
          return {
            ...result,
            [current]: setMinCountValues(minCount, dataType, value),
          };
        }

        if (current === 'userPassword') {
          return { ...result };
        }

        return {
          ...result,
          [current]: !_.isEmpty(value) ? value : [defaultMap[dataType]],
        };
      }

      if (extraProperties[EXTRA_PROPERTIES.minCount]) {
        const minCount = extraProperties[EXTRA_PROPERTIES.minCount][0];
        return {
          ...result,
          [current]: setMinCountValues(minCount, dataType),
        };
      }

      return {
        ...result,
        [current]: [defaultMap[dataType]],
      };
    }, { ...extraAttributes });

  return values;
}

/**
 * Submit the given form data and determine whether it was successful or not. See Formik
 * documentation on the "handleSubmit" function for more information.
 * UPDATE: setSubmitting is no longer needed for Formik 2.0
 * https://github.com/jaredpalmer/formik/issues/1957
 *
 * @param {{}} values The values controlled by Formik
 * @param {{}} props Passed down props
 */
export async function submitModelForm(values, props) {
  const initialValues = generateModelFormValues(props);
  const changedValues = Object
    .entries(values)
    .reduce((result, [key, value]) => {
      const newInitialValue = helpers.convertStringToArray(initialValues[key]);
      let newValue = helpers.convertStringToArray(value);
      const attributeMeta = _.get(props, `meta.attributes[${key}]`);
      const dataType = _.get(attributeMeta, 'dataType');

      // Allow false booleans && empty passwords to pass through on creation
      if (
        _.isEqual(newInitialValue, newValue) &&
        dataType !== FIELD_TYPES.BOOLEAN &&
        key !== 'userPassword'
      ) {
        return result;
      }

      newValue.forEach((newVal) => {
        if (newVal === '' || newVal === null) {
          newValue = null;
        }
      });

      return { ...result, [key]: newValue };
    }, {});
  try {
    await props.onSave(changedValues);
  } catch (e) {
    // Let actions handle any errors
  }
}

/**
 * Determine whether the form is in a valid state based on information on hand. See Formik
 * documentation on the "validate" function for more information.
 *
 * @param {{}} props The props supplied to the wrapped component
 * @returns {{}} An object mapping of errors to their associated form field
 */
export function validateSchema(props) {
  return Yup.lazy((values) => {
    const { attributes } = props.meta;
    const currentAttrs = Object
      .keys(values)
      .reduce((result, current) => ({
        ...result,
        [current]: attributes[current],
      }), {});
    const schema = Object
      .entries(currentAttrs)
      .reduce((result, [key, params]) => {
        const {
          dataType,
          extraProperties,
          multiValued,
          required,
        } = params;

        if (!YUP_TYPES[dataType]) {
          return { ...result };
        }

        let validator = Yup[YUP_TYPES[dataType]]()
          /*
           * Value is an array as set by generateModelFormValues function
           * Need to get the value out of array
           */
          .transform((cv, ov) => {
            if (Array.isArray(ov) && _.isUndefined(ov[0])) return ov[0];
            return cv;
          })
          .typeError('validation.invalid-type');

        if (required) {
          if (key === 'userPassword') {
            validator = Yup.string().required('validation.required');
          } else {
            validator = Yup.array().of(validator.required('validation.required')).nullable();
          }
        }

        if (!_.isEmpty(extraProperties)) {
          const {
            xAllowedValue,
            xMaxIntValue,
            xMaxValueLength,
            xMinIntValue,
            xMinValueLength,
            xValueRegex,
          } = helpers.setActiveExtraProperties(extraProperties);

          // String Length
          if (dataType === 'string') {
            if (xMinValueLength && xMaxValueLength) {
              validator = validator
                .min(xMinValueLength, 'validation.invalid-string.min')
                .max(xMaxValueLength, 'validation.invalid-string.max');
            }
          }

          // Integer Range
          if (dataType === 'integer') {
            if (xMinIntValue && xMaxIntValue) {
              validator = validator
                .min(xMinIntValue, 'validation.invalid-integer.min')
                .max(xMaxIntValue, 'validation.invalid-integer.max');
            }
          }

          // Regular Expressions
          if (xValueRegex) {
            validator = validator
              /*
               * In order to use the 'this' context
               * the test function must be a function expression (function test(value) {})
               */
              .test(function test(val) {
                const { createError, path } = this;
                const isRegexValid = xValueRegex.some(regex => RegExp(regex).test(val));

                if (!val) return true;

                return isRegexValid || createError({ path, message: 'validation.invalid-regex' });
              });
          }

          // Allowed Values
          if (xAllowedValue) {
            validator = validator
              .oneOf(
                (dataType === 'integer') ? xAllowedValue.map(num => Number(num)) : xAllowedValue,
                'validation.invalid-allow',
              );
          }
        }

        if (multiValued && !required) {
          validator = Yup.array().of(validator
            .test(function test() {
              const {
                createError,
                options: { originalValue },
                path,
              } = this;
              const vals = values[key].filter(val => !_.isUndefined(val));

              // Adding and removing
              if (_.isEmpty(vals)) {
                return true;
              }

              if (_.isUndefined(originalValue)) {
                return createError({ path, message: 'validation.required' });
              }

              return true;
            }))
            .nullable();
        }

        return {
          ...result,
          [key]: validator,
        };
      }, {});

    return Yup.object().shape(schema);
  });
}
