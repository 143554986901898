import { createActionType, SUCCESS, FAILURE, REQUEST } from '../../actions';
import * as types from '../../actions/actionTypes';


export const initialState = {
  data: undefined,
  links: undefined,
  selected: undefined,
  secondarySelected: undefined,
  selectedParentResource: {
    data: undefined,
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
  selectedGroupsResource: {
    data: undefined,
    meta: undefined,
    error: undefined,
    isRequesting: false,
  },
  create: {
    selected: undefined,
    secondarySelected: undefined,
    selectedGroupsResource: {
      data: undefined,
      meta: undefined,
      error: undefined,
      isRequesting: false,
    },
    selectedParentResource: {
      data: undefined,
      meta: undefined,
      error: undefined,
      isRequesting: false,
    },
    groupSelected: undefined,
  },
  edit: {
    isFetching: true,
  },
  filter: undefined,
  error: undefined,
};

function reduceResourceTypesSuccess(data, prevState) {
  return {
    ...prevState,
    ...data,
    filter: undefined,
    error: undefined,
  };
}

function reduceResourceTypesFailure(error, prevState) {
  return {
    ...prevState,
    data: undefined,
    links: undefined,
    error,
  };
}

function reduceResourceTypesSelection(selected, prevState) {
  return {
    ...prevState,
    selectedParentResource: undefined,
    selectedGroupsResource: undefined,
    selected,
  };
}

function reduceResourceTypesSelectionSecondary(selected, prevState) {
  return {
    ...prevState,
    secondarySelected: selected,
  };
}

function reduceParentResourceTypeRequest(CREATE, prevState) {
  let result = {
    ...prevState,
    selectedParentResource: {
      ...prevState.selectedParentResource,
      isRequesting: true,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedParentResource: {
          ...prevState.create.selectedParentResource,
          isRequesting: true,
        },
      },
    };
  }

  return result;
}

function reduceParentResourceTypeSuccess(data, CREATE, prevState) {
  let result = {
    ...prevState,
    selectedParentResource: {
      ...prevState.selectedParentResource,
      ...data,
      error: undefined,
      isRequesting: false,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedParentResource: {
          ...prevState.create.selectedParentResource,
          ...data,
          error: undefined,
          isRequesting: false,
        },
      },
    };
  }

  return result;
}

function reduceParentResourceTypeFailure(error, CREATE, prevState) {
  let result = {
    ...prevState,
    selectedParentResource: {
      ...prevState.selectedParentResource,
      data: undefined,
      meta: undefined,
      error,
      isRequesting: false,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedParentResource: {
          ...prevState.create.selectedParentResource,
          data: undefined,
          meta: undefined,
          error,
          isRequesting: false,
        },
      },
    };
  }

  return result;
}

function reduceParentResourceTypeClear(CREATE, prevState) {
  let result = {
    ...prevState,
    selectedParentResource: undefined,
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedParentResource: undefined,
      },
    };
  }

  return result;
}

function reduceGroupsResourceTypeRequest(CREATE, prevState) {
  let result = {
    ...prevState,
    selectedGroupsResource: {
      ...prevState.selectedGroupsResource,
      isRequesting: true,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedGroupsResource: {
          ...prevState.create.selectedGroupsResource,
          isRequesting: true,
        },
      },
    };
  }

  return result;
}

function reduceGroupsResourceTypeSuccess(data, CREATE, prevState) {
  let result = {
    ...prevState,
    selectedGroupsResource: {
      ...prevState.selectedGroupsResource,
      ...data,
      error: undefined,
      isRequesting: false,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedGroupsResource: {
          ...prevState.create.selectedGroupsResource,
          ...data,
          error: undefined,
          isRequesting: false,
        },
      },
    };
  }

  return result;
}

function reduceGroupsResourceTypeFailure(error, CREATE, prevState) {
  let result = {
    ...prevState,
    selectedGroupsResource: {
      ...prevState.selectedGroupsResource,
      data: undefined,
      meta: undefined,
      error,
      isRequesting: false,
    },
  };

  if (CREATE !== '') {
    result = {
      ...prevState,
      create: {
        ...prevState.create,
        selectedGroupsResource: {
          ...prevState.create.selectedGroupsResource,
          data: undefined,
          meta: undefined,
          error,
          isRequesting: false,
        },
      },
    };
  }

  return result;
}

function reduceResourceTypesFilter(filter, prevState) {
  return {
    ...prevState,
    filter,
  };
}

function reduceResourceTypesCreateSelect(selected, prevState) {
  return {
    ...prevState,
    create: {
      ...prevState.create,
      selected,
    },
  };
}

function reduceResourceTypesCreateSelectSecondary(selected, prevState) {
  return {
    ...prevState,
    create: {
      ...prevState.create,
      secondarySelected: selected,
    },
  };
}

function reduceResourceTypesCreateSelectGroup(selected, prevState) {
  return {
    ...prevState,
    create: {
      ...prevState.create,
      groupSelected: selected,
    },
  };
}

function reduceCreateParentResourceTypeClear(prevState) {
  return {
    ...prevState,
    create: {
      ...prevState.create,
      selectedParentResource: undefined,
    },
  };
}

function reduceEditReferenceRequest(prevState) {
  return {
    ...prevState,
    edit: {
      ...prevState.edit,
      isFetching: true,
    },
  };
}

function reduceEditReferenceSuccess(data, prevState) {
  return {
    ...prevState,
    edit: {
      ...prevState.edit,
      ...data,
      isFetching: false,
    },
  };
}

function reduceEditReferenceFailure(error, prevState) {
  return {
    ...prevState,
    edit: {
      ...prevState.edit,
      error,
      isFetching: false,
    },
  };
}

function reduceEditReferenceUpdateSuccess(data, prevState) {
  let { id } = data;
  const obj = {
    data: {
      ...prevState.edit.data,
      id,
      attributes: {
        ...data.updatedAttributes,
      },
    },
  };

  if (data.newId) {
    id = data.newId;
  }

  /*
   * The response from resetting the password will typically return an empty object
   * so we need to make sure we keep most previous attributes
   */
  if (data.hasNewPassword) {
    obj.data.attributes = {
      ...prevState.edit.data.attributes,
      ...obj.data.attributes,
    };
  }

  return {
    ...prevState,
    edit: {
      ...prevState.edit,
      ...obj,
    },
  };
}

export default function (state = initialState, action) {
  const CREATE = action.CREATE || '';
  switch (action.type) {
    case types.RESOURCE_TYPES[SUCCESS]:
      return reduceResourceTypesSuccess(action.data, state);
    case types.RESOURCE_TYPES[FAILURE]:
      return reduceResourceTypesFailure(action.error, state);
    case types.RESOURCE_TYPES_FILTER:
      return reduceResourceTypesFilter(action.filter, state);
    case types.SELECT_RESOURCE_TYPE:
      return reduceResourceTypesSelection(action.selected, state);
    case types.SELECT_SECONDARY_RESOURCE_TYPE:
      return reduceResourceTypesSelectionSecondary(action.selected, state);
    case types[`${createActionType(CREATE)}PARENT_RESOURCES`][REQUEST]:
      return reduceParentResourceTypeRequest(CREATE, state);
    case types[`${createActionType(CREATE)}PARENT_RESOURCES`][SUCCESS]:
      return reduceParentResourceTypeSuccess(action.data, CREATE, state);
    case types[`${createActionType(CREATE)}PARENT_RESOURCES`][FAILURE]:
      return reduceParentResourceTypeFailure(action.error, CREATE, state);
    case types[`CLEAR_${createActionType(CREATE)}PARENT_RESOURCES`]:
      return reduceParentResourceTypeClear(CREATE, state);
    case types[`${createActionType(CREATE)}GROUPS_RESOURCES`][REQUEST]:
      return reduceGroupsResourceTypeRequest(CREATE, state);
    case types[`${createActionType(CREATE)}GROUPS_RESOURCES`][SUCCESS]:
      return reduceGroupsResourceTypeSuccess(action.data, CREATE, state);
    case types[`${createActionType(CREATE)}GROUPS_RESOURCES`][FAILURE]:
      return reduceGroupsResourceTypeFailure(action.error, CREATE, state);
    case types.CREATE_SELECT_RESOURCE_TYPE:
      return reduceResourceTypesCreateSelect(action.selected, state);
    case types.CREATE_SELECT_SECONDARY_RESOURCE_TYPE:
      return reduceResourceTypesCreateSelectSecondary(action.selected, state);
    case types.CREATE_SELECT_GROUP_RESOURCE_TYPE:
      return reduceResourceTypesCreateSelectGroup(action.selected, state);
    case types.CLEAR_CREATE_PARENT_RESOURCES:
      return reduceCreateParentResourceTypeClear(state);
    case types.EDIT_REFERENCE[REQUEST]:
      return reduceEditReferenceRequest(state);
    case types.EDIT_REFERENCE[SUCCESS]:
      return reduceEditReferenceSuccess(action.data, state);
    case types.EDIT_REFERENCE[FAILURE]:
      return reduceEditReferenceFailure(action.error, state);
    case types.EDIT_REFERENCE_UPDATE[SUCCESS]:
      return reduceEditReferenceUpdateSuccess(action.data, state);
    default:
      return state;
  }
}
